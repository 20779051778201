import React, { useContext, useEffect, useState } from "react";
import { Grid, Switch } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import { FetchContext } from "../../context/FetchContext";
import Notification from "../../components/Notification";

const MessageStatus = ({ setOpenPopup }) => {
  const fetchContext = useContext(FetchContext);

  const [checked, setChecked] = useState(false);
  const [id, setid] = useState();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleChange = (event) => {
    if (id) {
      setChecked(event?.target?.checked);
      fetchContext.authAxios
        .post(`/message-settings`, {
          id: 1,
          is_send: event?.target?.checked ? 1 : 0,
        })
        .then(({ data }) => {
          setNotify({
            isOpen: true,
            message: "Message status Updated successfully!",
            type: "success",
          });
          setTimeout(() => {
            setOpenPopup(false);
          }, 2000);
          //   filterAction();
        })
        .catch((err) => {
          console.error(err);
          setNotify({
            isOpen: true,
            message: `There is some issue, please try again`,
            type: "error",
          });
        });
    }
  };

  const filterAction = async () => {
    fetchContext.authAxios
      .get(`/message-settings`)
      .then(({ data }) => {
        console.log("datatatat",data.data);
        
        setid(data.data);
        if (data.data[0].is_send === 1) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    filterAction();
  }, []);

  return (
    <>
      <div style={{ padding: "10px 50px" }}>
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        {checked ? "Yes" : "No"}
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};

export default MessageStatus;
