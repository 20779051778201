import React, { useState, useContext, useEffect } from 'react';
import { FetchContext } from '../../context/FetchContext';

const Displacement = () => {
  const fetchContext = useContext(FetchContext);
  //const classes = useStyles();
  const [sessionsGraphData, setSessionsGraphData] = useState([]);

  const getAllSessionGraphData = async (fetchContext, values) => {
    try {
      const { data } = await fetchContext.authAxios.post('/get-graph', values);
      setSessionsGraphData(data && data[0].data);
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  useEffect(() => {
    getAllSessionGraphData(fetchContext, {"graph_type":"MONTHLY"});
  }, [ fetchContext]);

  let greenhouseGasTotal = 0;
  let greenhouseGasAC = 0;
  let greenhouseGasDC = 0;

  let petrolemTotal = 0;
  let petrolemAC = 0;
  let petrolemDC = 0;

  sessionsGraphData && Object.values(sessionsGraphData).forEach((value) => {
    //const [key, value] = entry;
    if (value !== undefined) {
      for (const val of value) {
        if (val.charger_type === "AC") {
          greenhouseGasAC =
            greenhouseGasAC +
            ((val.meter_end - val.meter_start) / 1000) * 1.3165;
          petrolemAC =
            petrolemAC + (val.meter_end - val.meter_start) / 1000 / 6;
        }
        if (val.charger_type === "DC") {
          greenhouseGasDC =
            greenhouseGasDC +
            ((val.meter_end - val.meter_start) / 1000) * 1.3165;
          petrolemDC =
            petrolemDC + (val.meter_end - val.meter_start) / 1000 / 6;
        }
        if (val.meter_end && val.meter_start) {
          // greenhouseGasTotal = greenhouseGasTotal + ((val.meter_end - val.meter_start) / 1000) * 1.3165;
          // petrolemTotal = petrolemTotal + ((val.meter_end - val.meter_start) / 1000) / 6;
          greenhouseGasTotal = greenhouseGasDC + greenhouseGasAC;
          petrolemTotal = petrolemDC + petrolemAC;
        }
      }
    }
  });

  return (
    <>
      <div class="row my-3">
        <div class="col-md-6">
          <div class="card shadow mb-4" style={{borderRadius: '10px'}}>
            <div class="card-body">
              <div class="align-items-center">
                <div class="card-header row" style={{background: '#fff', fontSize: '17px', marginBottom: '21px'}}>
                  <div class="col-md-7"><strong class="card-title">Petroleum Displacement</strong> <span class="card-title small">(In gallons)</span> </div>  <div class="col-md-5 totalkwh textright">Total: <span>{petrolemTotal.toFixed(2)} gal</span> </div> 
                </div>
                <div class="row">
                <div class="col-md-6 textcenter1"><strong class="card-title acreading" style={{display:'flex', justifyContent:'center',  alignItems:'center'}}>{petrolemAC.toFixed(2)}</strong> <br/>
                <strong class="card-title">AC</strong>
                <span class="card-title small">(In gal)</span>
                
                </div>
                <div class="col-md-6 textcenter1"><strong class="card-title dcreading" style={{display:'flex', justifyContent:'center',  alignItems:'center'}}>{petrolemDC.toFixed(2)}</strong> <br/>
                <strong class="card-title">DC</strong>
                <span class="card-title small">(In gal)</span>
                
                </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card shadow mb-4" style={{borderRadius: '10px'}}>
            <div class="card-body">
              <div class="align-items-center">
                <div class="card-header row" style={{background: '#fff', fontSize: '17px', marginBottom: '21px'}}>
                  <div class="col-md-7"><strong class="card-title">GreenhouseGas </strong> <span class="card-title small">(In Kg)</span> </div>  <div class="col-md-5 totalkwh textright">Total: <span>{greenhouseGasTotal.toFixed(2)} Kg</span> </div> 
                </div>
                <div class="row">
                <div class="col-md-6 textcenter1"><strong class="card-title acreading" style={{display:'flex', justifyContent:'center',  alignItems:'center'}}><div>{greenhouseGasAC.toFixed(2)}</div></strong> <br/>
                <strong class="card-title">AC</strong>
                <span class="card-title small">(In Kg)</span>
                
                </div>
                <div class="col-md-6 textcenter1"><strong class="card-title dcreading" style={{display:'flex', justifyContent:'center',  alignItems:'center'}}>{greenhouseGasDC.toFixed(2)}</strong> <br/>
                <strong class="card-title">DC</strong>
                <span class="card-title small">(In Kg)</span>
                
                </div>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Displacement;
