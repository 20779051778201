import React, { useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import TimeZone from "../../components/controls/Timezone";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
// import AddIcon from '@material-ui/icons/Add';
import { MoreHorizRounded } from "@material-ui/icons";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ConfirmDialog from "../../components/ConfirmDialog";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import Popup from "../../components/Popup";
//import { Paper, Table, TableBody, TableCell, TableContainer, Tooltip, Toolbar, Typography, TableHead, TableRow } from '@mui/material';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography, makeStyles } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ExpandTable } from "../../components/controls/ExpandTable";
// import SessionStatus from './SessionStatus';
//import ActiveSessionsValues from './ActiveSessionsValues';
// import { MonthCalendar } from '@mui/x-date-pickers';
import { DateTime } from "luxon";
import ExportDTEData from "./ExportDTEData";
import ExportData from "./ExportData";
import Filter from "./Filter";
import SessionsPopUp from "./SessionsPopUp";
import { formatMaxPower } from "../../components/FormatMaxPower";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  filterIcon: {
    position: "absolute",
    right: "50px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },

  exportIcon: {
    position: "absolute",
    right: "10px",
    cursor: "pointer",
  },
  exportDTEIcon: {
    position: "absolute",
    right: "100px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
}));

const SessionsRfid = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  //const [recordForEdit, setRecordForEdit] = useState(null)
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [openPopup, setOpenPopup] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [showFilter, setShowFilter] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);

  //const dateStr = DateTime.now();
  //const [ filterPage, setFilterPage ] = useState(false);

  const [dTEdate, setDTEDate] = useState();

  const [filterObject, setFilterObject] = useState({
    manufacturer_id:null,
    rfid_name: null,
    uid:null,
    transaction_status: null,
    session_started_by: null,
    site_id: null,
    site_group_id: null,
    ocpp_cbid: null,
    max_energy: null,
    min_energy: null,
    session_id: null,
    from_date: null,
    to_date: null,
    export: false,
  });

  const headCells = [
    { id: "", label: "" },
    { id: "id", label: "ID" },
    { id: "name", label: "Charging Station" },
    { id: "ocpp_cbid", label: "OCPP CBID" },
    { id: "site", label: "Site" },
    { id: "site_group", label: "Site Account" },
    { id: "connector_id", label: "CID" },
    { id: "energy_delivered", label: "Energy Delivered (KWH)" },
    { id: "amount", label: "Amount (USD)" },
    { id: "status", label: "Status" },
    { id: "session_start", label: "Session Start" },
    { id: "session_end", label: "Session End" },
    { id: "duration", label: "Duration" },
    { id: "", label: "" },
  ];

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page, filterObject) => {
    try {
      const { data } = await fetchContext.authAxios.post(
        //`/transactions?page=${page}`
        `/get-rfid-transactions?page=${page}`,
        {
          rfid_name: filterObject.rfid_name,
          transaction_status: filterObject.transaction_status,
          uid: filterObject?.uid,
          site_id: filterObject.site_id,
          site_group_id: filterObject.site_group_id,
          ocpp_cbid: filterObject.ocpp_cbid,
          max_energy: filterObject.max_energy,
          min_energy: filterObject.min_energy,
          session_id: filterObject.session_id,
          manufacturer_id: filterObject?.manufacturer_id,
          from_date: filterObject.from_date
            ? DateTime.fromJSDate(filterObject.from_date).toFormat("dd-MM-yyyy")
            : filterObject.from_date,
          to_date: filterObject.to_date
            ? DateTime.fromJSDate(filterObject.to_date).toFormat("dd-MM-yyyy")
            : filterObject.to_date,
          start_type: filterObject?.start_type,
          charger_group_id: filterObject.charger_group_id,
          export: filterObject.export,
        }
      );

      setRecords(data.data);
      setTotalRecord(data.totalItems);


      if (data.totalItems && data.totalItems > 20) {
        let count = Math.ceil(data.totalItems / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    getAll(fetchContext, page, filterObject);
  }, [fetchContext, page, filterObject]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const openInPopup = (item) => {
    setTransactionData(item);
    setOpenPopup(true);
  };

  const onShowHideFilter = () => {
    setShowFilter((toggle) => !toggle);
    if (!showFilter) {
      setLoaded(false);
      setFilterObject({
        manufacturer_id:null,
        rfid_name: null,
        transaction_status: null,
        session_started_by:null,
        site_id: null,
        site_group_id: null,
        ocpp_cbid: null,
        max_energy: null,
        min_energy: null,
        session_id: null,
        from_date: null,
        to_date: null,
        export: false,
      });
    }
  };

  // const dateFormater = (value) => {
  //     switch (value) {
  //       case value.rfid_name.includes('enterprise'):
  //         return {rfid_name:'', start_type:''}
  //       case 'Yesterday':
  //         return moment(value).format('HH:mm')
  //       case 'Last 7 days':
  //         return moment(value).format('DD MMM')
  //       case 'Last 30 days':
  //         return moment(value).format('DD MMM')
  //       case 'This month':
  //         return moment(value).format('DD MMM')
  //       case 'This year':
  //         return moment(value).format("MMM 'YY")
  //       case 'Last year':
  //         return moment(value).format("MMM 'YY")
  //       case 'All time':
  //         return moment(value).format("MMM 'YY")
  //       default:
  //         return ''
  //     }
  //   }

  const onFilterSubmit = (values) => {
    setPage(1);
    setLoaded(false);
    setFilterObject({
      rfid_name: values.rfid_name,
      uid:values.rfid_name?.uid ? values.rfid_name?.uid : null,
      transaction_status: values.transaction_status !== "None" ? values.transaction_status : null,
      session_started_by: values.session_started_by !== "None" ? values.session_started_by : null,
      site_id: values.site_id !== "None" ? values.site_id : null,
      site_group_id: values.site_group_id !== "None" ? values.site_group_id : null,
      ocpp_cbid: values.ocpp_cbid,
      manufacturer_id: values?.manufacturer_id !== "None" ? values?.manufacturer_id : null,
      max_energy: values.max_energy,
      min_energy: values.min_energy,
      session_id: values.session_id,
      from_date: values.from_date,
      to_date: values.to_date,
      charger_group_id: values.charger_group_id,
      export: false,
    });
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
    setOpenPopup(false);
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              RFID Transactions Report
            </Typography>
          </div>
          <div className={classes.filterIcon} onClick={onShowHideFilter}>
            {showFilter ? (
              <Tooltip title="Filter">
                <FilterAltIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Filter">
                <FilterAltOffIcon />
              </Tooltip>
            )}
          </div>
          <div className={classes.exportIcon}>
            <ExportData filterObject={filterObject} />
          </div>
          {/* <div className={classes.exportDTEIcon}>
            DTE <ExportDTEData filterObject={filterObject} />
          </div> */}
        </Toolbar>

        {!showFilter && <Filter onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideFilter} />}
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
          //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item, index) => {
                return (
                  <ExpandTable
                    expandedContent={
                      <TableContainer>
                        {/* <SessionsRfid transaction_id={item.id}/> */}
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Plug In</TableCell>
                              <TableCell>Plug Out</TableCell>
                              <TableCell>SOC Start</TableCell>
                              <TableCell>SOC End</TableCell>
                              <TableCell>Type</TableCell>
                              {/* <TableCell>Amount</TableCell> */}
                              {/* <TableCell>Average Voltage</TableCell>
                                                <TableCell>Max Voltage</TableCell>   */}
                              {/* <TableCell>Average Power</TableCell> */}
                              <TableCell>Max Power</TableCell>
                              <TableCell>Mobile Number</TableCell>
                              <TableCell>Petroleum Displacement</TableCell>
                              <TableCell>GreenhouseGas</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {item?.plug_in && item?.timezone ? (
                                  <TimeZone
                                    dateTime={item.plug_in}
                                    timezone={item?.timezone.timezone}
                                  />
                                ) : (
                                  "--"
                                )}
                              </TableCell>
                              <TableCell>
                                {item?.plug_out && item?.timezone ? (
                                  <TimeZone
                                    dateTime={item.plug_out}
                                    timezone={item?.timezone.timezone}
                                  />
                                ) : (
                                  "--"
                                )}
                              </TableCell>
                              <TableCell>{item.soc_start}%</TableCell>
                              <TableCell>{item.soc_end}%</TableCell>
                              <TableCell>
                                {/* {item.type }
                                {`(${item?.rfid_name})`} */}
                                {`${item.type} ${item?.token ? `(${item.token})` : ""}`}
                              </TableCell>

                              {/* <TableCell>{item.type !== "FREE" && item.amount !== null && item.amount !== 0 ? item.amount : '--'}</TableCell> */}
                              {/* <TableCell>{item.average_voltage}</TableCell>
                                                <TableCell>{item.max_voltage}</TableCell>    */}
                              {/* {item.average_power && <TableCell>{parseInt(item.average_power)/1000}</TableCell>} */}
                              {/* <TableCell>{item.max_power}</TableCell> */}
                              <TableCell>
                                {formatMaxPower(
                                  item.max_power,
                                  item.connector?.charging_stations
                                    ?.manufacturer_id
                                )}
                              </TableCell>

                              <TableCell>
                                {(item?.frontend_user_id !== null ||
                                  item?.frontend_user_id !== 0) &&
                                item?.user_information?.phone_number
                                  ? `*****${(item?.user_information?.phone_number).slice(
                                      -4
                                    )}`
                                  : "--"}
                              </TableCell>
                              {item.meter_start !== null &&
                              item.meter_end !== null ? (
                                <TableCell>
                                  {(
                                    (item.meter_end - item.meter_start) /
                                    1000 /
                                    6
                                  ).toFixed(2)}
                                </TableCell>
                              ) : (
                                <TableCell>0.00</TableCell>
                              )}
                              {item.meter_start !== null &&
                              item.meter_end !== null ? (
                                <TableCell>
                                  {(
                                    ((item.meter_end - item.meter_start) /
                                      1000) *
                                    1.3165
                                  ).toFixed(2)}
                                </TableCell>
                              ) : (
                                <TableCell>0.00</TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    }
                    key={index}
                  >
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item?.name || "--"}</TableCell>
                    <TableCell>{item?.ocpp_cbid || "--"}</TableCell>
                    <TableCell>{item?.site_name || "--"}</TableCell>
                    <TableCell>{item?.site_group_name || "--"}</TableCell>
                    <TableCell>{item?.sequence_number || "--"}</TableCell>
                    {/* {item.meter_start && item.meter_end ? <TableCell>{(item.meter_end - item.meter_start) / 1000}</TableCell> : <TableCell>--</TableCell>  }  */}
                    <TableCell>
                      {item.energy ? Number(item.energy).toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item.amount > 0 ? item.amount.toFixed(2) : "0.00"}
                    </TableCell>
                    {/* <TableCell>{item.type !== "FREE" && item.amount !== null && item.amount ? item.amount.toFixed(2) : "0.00"}</TableCell> */}
                    <TableCell>{item.status}</TableCell>
                    {/* <TableCell><SessionsRfid transaction_id={item.id} /></TableCell> */}
                    <TableCell>
                      {item?.session_start && item?.timezone ? (
                        <TimeZone
                          dateTime={item.session_start}
                          timezone={item?.timezone}
                        />
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    <TableCell>
                      {item?.timezone &&
                      item.session_end ? (
                        <TimeZone
                          dateTime={item.session_end}
                          timezone={
                            item?.timezone
                          }
                        />
                      ) : (
                        "--"
                      )}
                    </TableCell>
                    {item.session_start && item.session_end ? (
                      <TableCell>
                        {DateTime.fromSQL(item.session_end)
                          .diff(DateTime.fromSQL(item.session_start))
                          .toFormat("hh:mm:ss")}
                      </TableCell>
                    ) : (
                      <TableCell>--</TableCell>
                    )}
                    <TableCell>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <MoreHorizRounded fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                  </ExpandTable>
                );
              })}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
          //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Popup title="Meter Values" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <SessionsPopUp transactionData={transactionData} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default SessionsRfid;
