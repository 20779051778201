import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
  Tooltip,
  Button,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useTable from "../../components/useTable";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Filter from "./Filter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { DateTime } from "luxon";
import { validate } from "@material-ui/pickers";
import { ArrowBack } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    // left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  backicon: {
    position: "absolute",
    // marginTop: '25px',
    right: "45px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    padding: "18px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  datePicker: {
    display: "inline-block",
    textAlign: "start",
  },
}));

const DeletedChargingStation = () => {
  const fetchContext = useContext(FetchContext);
  const History = useHistory();
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const headCells = [
    { id: "name", label: "Charging Station Name" },
    // { id: "connector_status", label: "Status" },
    { id: "ocpp_cbid", label: "ocpp cbid" },
    { id: "", label: "" },
    // { id: "", label: "" },
  ];
  const [showSearch, setShowSearch] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);

  const [filterObject, setFilterObject] = useState({
    // search_keyword: null,
    // from_date: null,
    // to_date: null,
    // // range: false,
    // charger_id: null,
    // idtag: null,
  });

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page, filterValues) => {
    try {
      const { data } = await fetchContext.authAxios.post(
        `/get-deleted-charging-stations?page=${page}`,
        filterValues
      );
      setRecords(data.data);
      setTotalRecord(data.total);
      if (data.total && data.total > 20) {
        let count = Math.ceil(data.total / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    getAll(fetchContext, page, filterObject);
  }, [fetchContext, page]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };
  const enableChargingStation = async (item) => {
    let dataobject = {
      ocpp_cbid: item?.ocpp_cbid,
      id: item?.id,
    };
    try {
      const { data } = await fetchContext.authAxios.post(
        `/restore-deleted-charging-stations`,
        dataobject
      );
      getAll(fetchContext, page, filterObject);
      if (data?.status) {
        setNotify({
          isOpen: true,
          message: `${data.data}`,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: `${data.data}`,
          type: "error",
        });
      }
    } catch (err) {
      setNotify({
        isOpen: true,
        message: "There is some issue, please try again",
        type: "error",
      });
      console.log("the err", err);
    }
  };
  const disableChargingStation = async (item) => {
    let dataobject = {
      id: item?.id,
      ctep: item?.ctep,
    };
    try {
      const { data } = await fetchContext.authAxios.post(
        `/enable-disable-ctep`,
        dataobject
      );
      getAll(fetchContext, page, filterObject);
      if (data?.status) {
        setNotify({
          isOpen: true,
          message: `${data.data}`,
          type: "success",
        });
      } else {
        setNotify({
          isOpen: true,
          message: `${data.data}`,
          type: "error",
        });
      }
    } catch (err) {
      setNotify({
        isOpen: true,
        message: "There is some issue, please try again",
        type: "error",
      });
      console.log("the err", err);
    }
  };
  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Invalid RFID Tag Report
            </Typography>
          </div>
          {fetchContext.isAdmin && (
            <div className={classes.searchIcon} onClick={onShowHideSearch}>
              {showSearch ? (
                <Tooltip title="Search">
                  <SearchIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Remove Search">
                  <SearchOffIcon />
                </Tooltip>
              )}
            </div>
          )}
          <div className={classes.filterIcon} onClick={onShowHideFilter}>
            {!showFilter ? (
              <Tooltip title="Filter">
                <FilterAltOffIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Filter">
                <FilterAltIcon />
              </Tooltip>
            )}
          </div>
        </Toolbar> */}
        <div className={classes.headerContainer}>
          <div className={classes.pageTitle}>Deleted Charging Data</div>
          <div className={classes.backicon}>
            <Tooltip title="Browse Back">
              <ArrowBack onClick={() => History.goBack()} />
            </Tooltip>
          </div>
          {/* <div className={classes.filterContainer}>
            <div className={classes.iconAlignment}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
              <div className={classes.filterIcon} onClick={onShowHideFilter}>
                {showFilter ? (
                  <Tooltip title="Filter">
                    <FilterAltIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Remove Filter">
                    <FilterAltOffIcon />
                  </Tooltip>
                )}
              </div>
            </div>
          </div> */}
        </div>
        {/* {!showSearch && (
          <Filter showSearch={showSearch} setFilterObject={setFilterObject} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideSearchCancle} />
        )}
        {!showFilter && (
          <Filter showSearch={showSearch} setFilterObject={setFilterObject} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideSearchCancle} />
        )} */}
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item) => {
                let string =
                  item?.sites?.length > 0 &&
                  item?.sites?.map((site) => site?.name).join(", ");
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item?.name ? item?.name : "-"}</TableCell>
                    {/* <TableCell>
                      {item?.connector_status ? item?.connector_status : "-"}
                    </TableCell> */}
                    <TableCell>
                      {item?.ocpp_cbid ? item?.ocpp_cbid : "-"}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: "#8a0a0b", color: "white" }}
                        onClick={() => {
                          enableChargingStation(item);
                        }}
                      >
                        enable
                      </Button>
                    </TableCell>
                    {/* <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          disableChargingStation(item);
                        }}
                      >
                        disable
                      </Button>
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
          />
        </Stack> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography spacing={2} style={{ color: "#8a0a0b" }} component="div">
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default DeletedChargingStation;
