//back up 3pm 03-07
import React, { useContext, useEffect, useState } from "react";
import { Table, TableCell, TableRow, makeStyles } from "@material-ui/core";
import { DateTime } from "luxon";
import { CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Bar, BarChart } from "recharts";
import { FetchContext } from "../../../context/FetchContext";
import { TYPE_1, TYPE_2, TYPE_3 } from "../RevenueReport/RevenueType";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  newButton: {
    position: "absolute",
    right: "0px",
    //width: '75%'
  },

  exportIcon: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1),
  },
}));

const SiteRevenueUsage = ({ byMonth, filterObject, energyRevenue, setSitePayoutCal,records }) => {
  const [data, setData] = useState();
  const [graphData, setGraphData] = useState();
  const [maxValue, setMaxValue] = useState(250);
  const classes = useStyles();
  const fetchContext = useContext(FetchContext);
  const currentDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() - 30);
  const [startDate, setStartDate] = useState(futureDate);
  const [endDate, setEndDate] = useState(new Date());

  const handleFilterData = (data, date, { range, to_date, from_date }) => {
    const dailyData = {};
    let start;
    let end;
    let transactions = data;
    if (!transactions) {
      return;
    }

    if (range) {
      let given_to_Date = DateTime.fromFormat(to_date, "dd-MM-yyyy");
      let to_js_date = given_to_Date.toJSDate();

      let given_from_Date = DateTime.fromFormat(from_date, "dd-MM-yyyy");
      let from_js_date = given_from_Date.toJSDate();

      start = DateTime.fromJSDate(from_js_date);
      end = DateTime.fromJSDate(to_js_date);
    } else {
      let givenDate = DateTime.fromFormat(date, "dd-MM-yyyy");
      let startDate = givenDate.toJSDate();
      let endDate = givenDate.endOf("month").toJSDate();
      start = DateTime.fromJSDate(startDate);
      end = DateTime.fromJSDate(endDate);
    }

    let datetime = start.startOf("day");
    while (datetime < end.endOf("day")) {
      dailyData[datetime.toISO()] = {
        datetime,
        count: 0,
        amount: 0,
        site_payout: 0,
      };
      datetime = datetime.plus({ days: 1 });
    }

    transactions.forEach((transaction) => {
      const key = DateTime.fromSQL(transaction.graph_date).startOf("day").toISO();
      if (key && dailyData[key]) {
        dailyData[key].count++;
        dailyData[key].amount += transaction.amount;
        dailyData[key].site_payout += transaction.site_payout;
      }
    });

    setData(
      Object.values(dailyData).reduce((acc, { count, datetime, amount, site_payout }) => {
        const dt = DateTime.fromMillis(datetime.ts);
        const month = dt.monthLong;

        return [
          ...acc,
          datetime !== undefined && {
            amount: amount.toFixed(2),
            average: count ? getRound(site_payout, count) : 0,
            month,
            site_payout: site_payout,
            site_payout_for_graph: site_payout.toFixed(2),
            date: datetime,
          },
        ];
      }, [])
    );
    const reducedData = transactions?.reduce((acc, item) => {  
      let site_payout = item?.site_payout;

      if (!acc[item.site_id]) {
        acc[item.site_id] = {
          site_payout: site_payout,
        };
      } else {
        acc[item.site_id].site_payout += site_payout;
      }
      return acc;
    }, {});
    setSitePayoutCal(reducedData)
  };

  useEffect(() => {
      if(data){
        let newData = data.map(item => ({
          ...item, 
          site_payout: item.site_payout < 0 ? 0 : item.site_payout,
          site_payout_for_graph:item?.site_payout_for_graph < 0 ? 0 : item.site_payout_for_graph,

      }));
      setGraphData(newData)
      }
     
  }, [data]);
  useEffect(() => {
    handleFilterData(energyRevenue, byMonth, { range: filterObject?.range, from_date: filterObject.from_date, to_date: filterObject.to_date });
  }, [energyRevenue]);

  const headCells = [{ id: "", label: "" }];

  const getRound = (value1, value2) => {
    let response = 0;
    if (value2 !== undefined) response = (value1 / value2).toFixed(2);
    else response = value1 / value2;

    return response;
  };

  const getTotal = (data) => {
    let allTotal = 0;
    // let allAverage = 0;
    let number_of_days = data?.length;
    let final_average = 0;
    if (data) {
      for (const d of data) {
        let field = Number(d.site_payout);
        // if (field > 0) {
        allTotal = allTotal + field;
        // }
      }
    }
    final_average = allTotal > 0 ? (allTotal / number_of_days).toFixed(2) : "0.00";
    function formatAllTotal(allTotal) {
      let strTotal = allTotal.toString();
      let decimalIndex = strTotal.indexOf(".");
      if (decimalIndex !== -1) {
        return strTotal.slice(0, decimalIndex + 3); // Include up to two digits after decimal
      } else {
        return strTotal;
      }
    }
    return {
      allTotal: formatAllTotal(allTotal),
      // allAverage: allAverage.toFixed(2)
      average: final_average,
    };
  };

  useEffect(() => {
    if (data) {
      const maxUsage = data.reduce((max, obj) => {
        const usage = parseFloat(obj.amount);
        return usage > max ? usage : max;
      }, 0);

      const nextMultiple = Math.ceil(maxUsage / 10) * 10;
      setMaxValue(nextMultiple);
    }
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-light p-2">
          <p className="py-0 my-0">{`${payload[0].payload.month} ${label}`}</p>
          <p className="py-0 my-0 text-danger">{`Site Payout : $ ${payload[0]?.payload?.site_payout.toFixed(2)}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className={classes.pageContent}>
        <div class="card shadow mb-4" style={{ borderRadius: "10px" }}>
          <div class="card-body">
            <div class="card-header row" style={{ background: "#fff" }}>
              <div class="col-md-6">
                <strong class="card-title">Revenue </strong> <span class="card-title">(USD)</span>{" "}
              </div>
              {/* <div class="col-md-3 totalkwh">&nbsp;</div> */}
              <div class="col-md-3 totalkwh">
                Total <span>{getTotal(data).allTotal}</span>{" "}
              </div>
              <div class="col-md-3 totalkwh">
                {" "}
                Average <span>{getTotal(data).average}</span>
              </div>
            </div>
            <div class="clearfix"></div>
            <div className="d-flex flex-row">
              <div
                style={{
                  writingMode: "vertical-rl",
                  transform: "rotate(-180deg)",
                  width: "fit-content",
                }}
                className="text-vertical text-center"
              >
                Site Payout (USD)
              </div>
              <Table>
                <TableRow>
                  <TableCell>
                    <div className="responsiveCharts">
                      <ResponsiveContainer>
                        <BarChart
                          data={graphData?.map((dt) => {
                            return {
                              ...dt,
                              date: dt?.date?.c?.day,
                            };
                          })}
                          margin={{
                            top: 0,
                            right: 0,
                            left: -9,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="1 1" />
                          <XAxis dataKey="date" />
                          <YAxis domain={[0, maxValue]} tickCount={11} />
                          <Tooltip content={<CustomTooltip />} />
                          <Bar type="monotone" dataKey="site_payout_for_graph" fill="#a11011" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </TableCell>
                </TableRow>
              </Table>
            </div>
            <div className="text-center pt-0 mt-0">
              <span style={{ fontSize: "24px", lineHeight: 1.3, color: "rgb(161, 16, 17)" }}>&#9632;</span> <span>Date</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteRevenueUsage;