import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../../context/FetchContext";
import useTable from "../../../components/useTable";
//import Controls from "../../components/controls/Controls";
// import AddIcon from '@material-ui/icons/Add';
// import Popup from "../../components/Popup";
// import { MoreHorizRounded } from '@material-ui/icons';
// import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../../components/Loader";
import ExportDataByRevenueReport from "./ExportDataByRevenueReport";
import ExportDataByRevenueReportNext from "./ExportDataByRevenueReportNext";
import Notification from "../../../components/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { Paper, TableBody, TableCell, Toolbar, Typography, Tooltip, TableRow } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
//import { ExpandTable } from '../../components/controls/ExpandTable';
import { Link, makeStyles, TableHead } from "@material-ui/core";
import MonthRangeSelect from "../MonthRangeSelect";
import { Link as RouterLink } from "react-router-dom";
import { DateTime } from "luxon";
import { InfoOutlined } from "@material-ui/icons";
// import SessionStatus from './SessionStatus';
//import ActiveSessionsValues from './ActiveSessionsValues';
//import SessionsPopUp from './SessionsPopUp';
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import RevenuReportFilter from "./RevenuReportFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useParams } from "react-router-dom";
import RevenueReportGraph from "./RevenueReportGraph";
import { TYPE_1, TYPE_2, TYPE_3 } from "./RevenueType";

const useStyles = makeStyles((theme) => ({
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    padding: "18px 0",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "18px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: "18px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  datePicker: {
    display: "inline-block",
    textAlign: "start",
  },
  textAlignment: {
    [theme.breakpoints.between("xs", "sm")]: {
      // alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
      // gap: "10px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // gap: "10px",
  },
  pageTotalText: {
    color: theme.palette.secondary.main,
    // position: "absolute",
    // left: "220px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    fontWeight: "bold",
  },
}));

const RevenueReport = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  //const [recordForEdit, setRecordForEdit] = useState(null)
  const [loaded, setLoaded] = useState(false);
  const dateStr = DateTime.now();
  const selectedMonth = dateStr.toFormat(`01-${"MM-yyyy"}`);
  const [page, setPage] = useState(1);
  const [byMonth, setByMonth] = useState(selectedMonth);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  const [totalRevenue, setTotalRevenue] = useState(0);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  //const [openPopup, setOpenPopup] = useState(false)
  //const [transactionId, setTransactionId] = useState(0)
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showSearch, setShowSearch] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);

  const [showFilter, setShowFilter] = useState(true);
  const [filterObject, setFilterObject] = useState({
    search_keyword: null,
    from_date: null,
    to_date: null,
    range: false,
    // date:byMonth
  });

  const headCells = [
    { id: "", label: "" },
    { id: "site_name", label: "Site Name" },
    { id: "amount", label: "Amount (USD)" },
    { id: "energy", label: "Energy (KWH)" },
    { id: "charging_duration", label: "Average Charging Duration (Minutes)" },
    { id: "transactions", label: "Number of Sessions" },
    { id: "", label: "" },
    { id: "", label: "" },
  ];

  const {
    TblContainer,
    //TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  // const openInPopup = item => {
  //     setTransactionId(item)
  //     setOpenPopup(true)
  // }

  const getAll = async (fetchContext, page, byMonth, filterObject) => {
    try {
      const { data } = await fetchContext.authAxios.post(`/get-report-by-site?page=${page}`, {
        date: byMonth,
        export: false,
        ...filterObject,
      });
      setRecords(data.data);
      setTotalRecord(data.totalItems);
      if (data.totalItems && data.totalItems > 20) {
        let count = Math.ceil(data.totalItems / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  const getCountAll = async (fetchContext, byMonth, filterObject) => {
    try {
      const { data } = await fetchContext.authAxios.post(`/get-report-by-site-all-for-revenue?page=${page}`, {
        date: byMonth,
        export: false,
        ...filterObject,
      });
      setTotalRevenue(data?.total_amount);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    getCountAll(fetchContext, byMonth, filterObject);
  }, [fetchContext, byMonth, filterObject]);

  const changeSelectOptionHandler = (event) => {
    let report_month = event.target.value;
    setLoaded(false);
    setByMonth(report_month);
    setShowFilter(true);
    setPage(1);
    getAll(fetchContext, 1, report_month, {
      ...filterObject,
      from_date: null,
      to_date: null,
      range: false,
    });
    setFilterObject({
      ...filterObject,
      from_date: null,
      to_date: null,
      range: false,
    });
    //setSelected(event.target.value);
  };

  useEffect(() => {
    getAll(fetchContext, page, byMonth, filterObject);
  }, [fetchContext, page]);

  const onShowHideSearch = () => {
    setShowSearch(!showSearch);
    setShowFilter(true);
    setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1, byMonth, { search_keyword: null, range: false });
    setFilterObject({
      search_keyword: null,
      range: false,
    });
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
  };

  const onFilterSubmit = (values) => {
    setPage(1);
    let updatedValues = {
      ...values,
      from_date: values.from_date ? DateTime.fromJSDate(values.from_date).toFormat("dd-MM-yyyy") : null,
      to_date: values.to_date ? DateTime.fromJSDate(values.to_date).toFormat("dd-MM-yyyy") : null,
      range: !showSearch ? false : true,
      date: byMonth,
    };
    getAll(fetchContext, page, byMonth, updatedValues);
    setFilterObject(updatedValues);
  };

  const onShowHideFilter = () => {
    setShowFilter(!showFilter);
    setShowSearch(true);
    getAll(fetchContext, 1, byMonth, {
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
    setFilterObject({
      ...filterObject,
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
  };

  const onShowHideSearchCancle = () => {
    setShowSearch(true);
    setShowFilter(true);
    setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1, byMonth, {
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
    setFilterObject({
      search_keyword: null,
      is_search: !showSearch,
      from_date: null,
      to_date: null,
      range: false,
    });
  };

  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  const sitePayout = (item, licensing_fee, final_amount, processing_fee) => {
    if (final_amount < 0) {
      return 0;
    }
    let fees = item?.connector?.charging_stations?.site?.processing_fee;
    if (fees?.length > 0 && fees[0]?.agreement_type) {
      switch (fees[0]?.agreement_type) {
        case TYPE_1:
          return licensing_fee;
        case TYPE_2:
          return final_amount;
        case TYPE_3:
          return item.amount - item?.tax_amount - processing_fee;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  const agreement_type_class = (item) => {
     let agreement_type = item?.processingFee
    if (agreement_type) {
      switch (agreement_type?.agreement_type) {
        case TYPE_1:
          return { color: "#FF0000" };
        case TYPE_2:
          return { color: "#000000" };
        case TYPE_3:
          return { color: "#CC5500" };
        default:
          return {};
      }
    } else {
      return {};
    }
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Revenue Report
            </Typography>
          </div>

          <div className={classes.pageTotalText}>(Total Revenue Amount: $ {<span className={classes.boldtext}>{totalRevenue?.toFixed(2)}</span>})</div>

          {fetchContext.isAdmin && (
            <div className={classes.searchIcon} onClick={onShowHideSearch}>
              {showSearch ? (
                <Tooltip title="Search">
                  <SearchIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Remove Search">
                  <SearchOffIcon />
                </Tooltip>
              )}
            </div>
          )}
          <div className={classes.filterIcon} onClick={onShowHideFilter}>
            {!showFilter ? (
              <Tooltip title="Filter">
                <FilterAltOffIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Filter">
                <FilterAltIcon />
              </Tooltip>
            )}
          </div>

          <div className={classes.newButton}>
            <MonthRangeSelect label="Filter by Month" name="report_month" value={byMonth} required onChange={changeSelectOptionHandler} />
          </div>
        </Toolbar> */}
        <div className={classes.headerContainer}>
          <div className={classes.textAlignment}>
            <div className={classes.pageTitle}>Revenue Report</div>
            <div className={classes.pageTotalText}>(Total Revenue Amount: $ {<span className={classes.boldtext}>{totalRevenue?.toFixed(2)}</span>})</div>
          </div>
          <div className={classes.filterContainer}>
            <div className={classes.iconAlignment}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
              <div onClick={onShowHideFilter}>
                <div className={classes.filterIcon}>
                  {showFilter ? (
                    <Tooltip title="Filter">
                      <FilterAltIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Filter">
                      <FilterAltOffIcon />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.datePicker}>
              <div className={classes.newButton}>
                <MonthRangeSelect
                  label="Filter by Month"
                  name="report_month"
                  value={byMonth}
                  //error={errors.charger_type}
                  required
                  onChange={changeSelectOptionHandler}
                />
              </div>
            </div>
          </div>
        </div>
        {!showSearch && (
          <RevenuReportFilter
            showSearch={showSearch}
            setFilterObject={setFilterObject}
            onFilter={onFilter}
            filterObject={filterObject}
            oncancle={onShowHideSearchCancle}
          />
        )}
        {!showFilter && (
          <RevenuReportFilter
            showSearch={showSearch}
            setFilterObject={setFilterObject}
            onFilter={onFilter}
            filterObject={filterObject}
            oncancle={onShowHideSearchCancle}
          />
        )}
        {/* <RevenueReportGraph byMonth={byMonth} /> */}

        <TblContainer>
          {/* <TblHead /> */}
          <TableHead>
            <TableRow>
              <TableCell className={classes.tblCellInside} />
              <TableCell className={classes.tblCellInside}>Site Name</TableCell>
              <TableCell className={classes.tblCellInside}>Energy (KWH)</TableCell>
              <TableCell className={classes.tblCellInside}>Number of Sessions</TableCell>
              <TableCell className={classes.tblCellInside}>Net Amount (USD)</TableCell>
              <TableCell className={classes.tblCellInside}>Parking Fee (USD)</TableCell>
              <TableCell className={classes.tblCellInside}>Tax (USD)</TableCell>
              <TableCell className={classes.tblCellInside}>Amount (USD)</TableCell>
              <TableCell className={classes.tblCellInside}>Processing Fee (USD)</TableCell>
              <TableCell className={classes.tblCellInside}>Licensing Fee (USD)</TableCell>
              {/* <TableCell className={classes.tblCellInside}>Fixed Fee (USD)</TableCell>
              <TableCell className={classes.tblCellInside}>Flat Deduction Per Session(USD)</TableCell> */}
              <TableCell className={classes.tblCellInside}>Final Amount (USD)</TableCell>
              <TableCell className={classes.tblCellInside}>Site Payout (USD)</TableCell>
              <TableCell className={classes.tblCellInside}>{records?.length > 0 && <ExportDataByRevenueReport filterDate={filterObject} byMonth={byMonth} />}</TableCell>
              <TableCell className={classes.tblCellInside} />
            </TableRow>
          </TableHead>
          {loaded ? (
            <TableBody>
              {records.map((item, index) => {
                // let processing_fee = item?.connector?.charging_stations?.site?.processing_fee?.reduce((acc, current) => {
                //   if (current?.processing_fee_type === "percentage") {
                //     return acc + (item?.amount - item?.tax_amount) * (current?.processing_fee / 100);
                //   } else {
                //     return acc + current?.processing_fee * item?.total;
                //   }
                // }, 0);
                // // let fixed_fee = item?.connector?.charging_stations?.site?.processing_fee?.reduce((acc, current) => acc + current?.fixed_fee_per_month, 0).toFixed(2);
                // let licensing_fee = item?.connector?.charging_stations?.site?.processing_fee?.reduce(
                //   (acc, current) => acc + current?.licensing_fee_energy * Number(item?.energy),
                //   0
                // );

                // // let flat_deduction = (process.env.REACT_APP_FLAT_DEDUCTION_PER_SESSION * item?.total).toFixed(2);
                // let final_amount = item?.amount - item?.tax_amount - processing_fee - licensing_fee;
                return (
                  <TableRow key={index}>
                    <TableCell />
                    <TableCell style={agreement_type_class(item)}>
                      {item.site_name}
                    </TableCell>
                    <TableCell>
                      {item.energy ? Number(item.energy).toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell>{item.total}</TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item?.amount
                        ? formatNumber(item.amount - item?.tax_amount)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item?.parking_amount
                        ? formatNumber(item?.parking_amount)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item?.tax_amount
                        ? formatNumber(item?.tax_amount)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item?.amount
                        ? formatNumber(item.amount)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item.processingFee.processing_fee
                        ? item.processingFee.processing_fee.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item.processingFee.licensing_fee
                        ? item.processingFee.licensing_fee.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    {/* <TableCell>{fixed_fee}</TableCell>
                    <TableCell>{flat_deduction}</TableCell> */}
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item.processingFee.final_amount
                        ? item.processingFee.final_amount.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item?.amount <= 0
                        ? "0.00"
                        : item.processingFee.site_payout
                        ? item.processingFee.site_payout.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="View More">
                        <Link
                          component={RouterLink}
                          to={`/${"revenue-report-next"}/${item.site_id}/${
                            filterObject?.to_date && filterObject?.from_date
                              ? `${filterObject?.from_date}to${filterObject?.to_date}`
                              : byMonth
                          }?amount=${item?.amount.toFixed(2)}`}
                        >
                          <InfoOutlined />
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <ExportDataByRevenueReportNext
                        siteId={item.site_id}
                        byMonth={byMonth}
                        filterDate={filterObject}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default RevenueReport;