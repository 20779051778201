import React, { useContext, useState } from "react";
//import { DateTime } from 'luxon';
import CsvDownloader from "react-csv-downloader";
import { FetchContext } from "../../../context/FetchContext";
import { ImportExport } from "@material-ui/icons";
import { CircularProgress, Snackbar, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { TYPE_1, TYPE_2, TYPE_3 } from "./RevenueType";

const ExportDataByRevenueReport = ({ filterDate, byMonth }) => {
  const fetchContext = useContext(FetchContext);

  const [loading, setLoading] = useState(false); // State to manage loading
  const [showMessage, setShowMessage] = useState(false); // State to manage message display
  const formatNumber = (num) => {
    return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(2);
  };

  const sitePayout = (item, licensing_fee, final_amount, processing_fee) => {
    if (final_amount < 0) {
      return 0;
    }
    let fees = item?.connector?.charging_stations?.site?.processing_fee;
    if (fees?.length > 0 && fees[0]?.agreement_type) {
      switch (fees[0]?.agreement_type) {
        case TYPE_1:
          return licensing_fee;
        case TYPE_2:
          return final_amount;
        case TYPE_3:
          return item.amount - item?.tax_amount - processing_fee;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  const asyncFnComputeData = async () => {

setLoading(true); // Set loading to true before fetching data
    setShowMessage(true); // Show message when starting download
    try {
      const dataArr = [];
      const finalPayload = {
        ...filterDate,
        export: true,
        date: byMonth,
      };
      await fetchContext.authAxios
        .post(`/get-report-by-site`, finalPayload)
        .then(({ data }) => {
          const pdata = data.data;

          if (pdata) {
            for (let item of pdata) {
              // let processing_fee = item?.connector?.charging_stations?.site?.processing_fee?.reduce((acc, current) => {
              //   if (current?.processing_fee_type === "percentage") {
              //     return acc + (item?.amount - item?.tax_amount) * (current?.processing_fee / 100);
              //   } else {
              //     return acc + current?.processing_fee * item?.total;
              //   }
              // }, 0);
              // // let fixed_fee = item?.connector?.charging_stations?.site?.processing_fee?.reduce((acc, current) => acc + current?.fixed_fee_per_month, 0).toFixed(2);
              // let licensing_fee = item?.connector?.charging_stations?.site?.processing_fee?.reduce(
              //   (acc, current) => acc + current?.licensing_fee_energy * Number(item?.energy),
              //   0
              // );

              // let flat_deduction = (process.env.REACT_APP_FLAT_DEDUCTION_PER_SESSION * item?.total).toFixed(2);
              // let final_amount = item?.amount - item?.tax_amount - processing_fee - licensing_fee;
              let site_payout = item.processingFee.site_payout;
              let processing_fee = item.processingFee.processing_fee;
              let licensing_fee = item.processingFee.licensing_fee;
              let final_amount = item.processingFee.final_amount;

              let obj = {
                site: item.site_name,
                site_group:
                  item.connector.charging_stations.site.site_group?.name || "",
                agreement_type: item.processingFee.agreement_type || "",
                amount: item?.amount <= 0 ? "0.00" : formatNumber(item.amount),
                energy: item.energy ? Number(item.energy).toFixed(2) : 0,
                duration: item?.timezone_duration
                  ? (item.timezone_duration / 60 / item.total).toFixed(2)
                  : "-",
                total: item.total,
                charging_station_name: item.charging_station_name,
                address: item.address?.replace(/,/g, ""),
                city: item.city,
                state: item.state,
                postal_code: item.postal_code,
                latitude: item.latitude,
                longitude: item.longitude,
                parking_amount:
                  item.amount <= 0
                    ? "0.00"
                    : item?.parking_amount
                    ? formatNumber(item?.parking_amount)
                    : 0,
                tax_amount:
                  item.amount <= 0
                    ? "0.00"
                    : item?.tax_amount
                    ? formatNumber(item?.tax_amount)
                    : 0,
                net_amount:
                  item.amount <= 0
                    ? "0.00"
                    : formatNumber(item?.amount - item?.tax_amount),
                processing_fee:
                  item.amount <= 0
                    ? "0.00"
                    : processing_fee
                    ? processing_fee.toFixed(2)
                    : 0,
                // fixed_fee: fixed_fee,
                // flat_deduction: flat_deduction,
                licensing_fee:
                  item.amount <= 0
                    ? "0.00"
                    : licensing_fee
                    ? licensing_fee.toFixed(2)
                    : 0,
                final_amount:
                  item.amount <= 0
                    ? "0.00"
                    : final_amount
                    ? final_amount.toFixed(2)
                    : 0,
                site_payout:
                  item.amount <= 0 ? "0.00" : site_payout?.toFixed(2),
              };
              dataArr.push(obj);
            }
            setLoading(false); // Set loading to false after fetching data
            setShowMessage(false); // Show message when starting download
          }
        });
      return Promise.resolve(dataArr);
    } catch (error) {
      console.log("error", error);
    }
  };

  const columns = [
    { id: "site", displayName: "Site Name" },
    { id: "site_group", displayName: "Site Account" },
    { id: "agreement_type", displayName: "Agreement Type" },
    { id: "energy", displayName: "Energy Delivered (KWH)" },
    // { 'id': 'duration', 'displayName': 'Average Charging Duration (Minutes)' },
    { id: "total", displayName: "Number of Sessions" },
    // { 'id': 'charging_station_name', 'displayName': 'Charging Station' },
    // { 'id': 'duration', 'displayName': 'Average Charging Duration (Minutes)' },
    { id: "total", displayName: "Number of Sessions" },
    { id: "net_amount", displayName: "Net Amount (USD)" },
    { id: "parking_amount", displayName: "Parking Fee (USD)" },
    { id: "tax_amount", displayName: "Tax (USD)" },
    { id: "amount", displayName: "Amount (USD)" },
    { id: "processing_fee", displayName: "Processing Fee (USD)" },
    // { id: "fixed_fee", displayName: "Fixed Fee (USD)" },
    // { id: "flat_deduction", displayName: "Flat Deduction Per Session(USD)" },
    { id: "licensing_fee", displayName: "Licensing Fee (USD)" },
    { id: "final_amount", displayName: "Final Amount (USD)" },
    { id: "site_payout", displayName: "Site Payout (USD)" },
    { id: "address", displayName: "Address" },
    { id: "city", displayName: "City" },
    { id: "state", displayName: "State" },
    { id: "postal_code", displayName: "Zipcode" },
  ];

  const revenueFileName = () => {
    if (filterDate?.range) {
      const from_date = filterDate?.from_date ? filterDate?.from_date : "";
      const from_date_parsedDate = DateTime.fromFormat(from_date, "dd-MM-yyyy");
      const from_date_formattedDate =
        from_date_parsedDate.toFormat("d LLL yyyy");

      const to_date = filterDate?.to_date ? filterDate?.to_date : "";
      const to_date_parsedDate = DateTime.fromFormat(to_date, "dd-MM-yyyy");
      const to_date_formattedDate = to_date_parsedDate.toFormat("d LLL yyyy");
      if (
        from_date_formattedDate !== "Invalid DateTime" &&
        to_date_formattedDate !== "Invalid DateTime"
      ) {
        return `RevenueReport (${from_date_formattedDate} to ${to_date_formattedDate})`;
      } else {
        return `RevenueRReport`;
      }
    } else {
      const date = filterDate?.date ? filterDate?.date : byMonth ? byMonth : "";
      const date_parsedDate = DateTime.fromFormat(date, "dd-MM-yyyy");
      const date_formattedDate = date_parsedDate.toFormat("LLL yyyy");
      if (date_formattedDate !== "Invalid DateTime") {
        return `RevenueReport (${date_formattedDate})`;
      } else {
        return `RevenueReport`;
      }
    }
  };

  return (
    <>
      <CsvDownloader
        filename={revenueFileName()}
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          {/* <ImportExport style={{ fill: "#fff" }} /> */}
          {loading ? <CircularProgress size={24} /> : <ImportExport  style={{ fill: "#fff" }}/>}
        </Tooltip>
      </CsvDownloader>

      {/* Snackbar to display message */}
      <Snackbar
        open={showMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position at top center
        style={{ backgroundColor: "transperent" }} // Customize color to red
        message="Downloading data..."
      />
    </>
  );
};

export default ExportDataByRevenueReport;