import { CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Controls from "../../components/controls/Controls";
import { FetchContext } from '../../context/FetchContext';

const ChargingStationSelect = (props) => {
  const { value = '', error = null, onChange } = props;
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const getAllSites = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          '/select-charging-stations'
        );
        setValues(data.data.map((item,index) => {
          return {
            id: item.name,
            label: item.name,
            charger_id_new:item?.id
          }
        }));
        setLoaded(true);
      } catch (err) {
        console.log('the err', err);
      }
    };

    getAllSites(fetchContext);

  }, [fetchContext]);


  return (
    <>
      {loaded
        ?
        <Controls.SearchWithSelect
          name="charger_id"
          label="Charging Stations"
          value={value}
          required={true}
          onChange={onChange}
          options={values}
          error={error}
          style={{ width: "100%" }}
        />
        :
        // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>}  />
        <Controls.Input name="charger_id" label="Charging Stations" style={{ width: "95%" }}><CircularProgress size="1rem" /> </Controls.Input>
      }

    </>

  )
}

export default ChargingStationSelect;
