import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import FeeTypeSelect from "./FeeTypeSelect";
import ParkingTypeSelect from "./ParkingTypeSelect";
import { useIsDesktop } from "../../context/DisplayContext";
import WeeklySchedule from "./WeeklySchedule";
import TimezonesSelect from "../Sites/TimezonesSelect";
import { getTimeZones } from "@vvo/tzdb";
import { Country } from "country-state-city";
import ContriesSelect from "../Sites/ContriesSelect";
import { DateTime } from "luxon";
import { FetchContext } from "../../context/FetchContext";
const timeZones = getTimeZones();
const countries = Country.getAllCountries();

function convertToUTCWithLuxon(utcTime, timezone) {
  const [hours, minutes] = utcTime.split(":").map(Number);
  const dateTimeInUTC = DateTime.fromObject(
    { hour: hours, minute: minutes },
    { zone: "utc" }
  );
  const dateTimeInTimezone = dateTimeInUTC.setZone(timezone);
  return dateTimeInTimezone.toFormat("HH:mm");
}

const PricesForm = (props) => {
  const { addOrEdit, recordForEdit, closeForm, editslot, setEditslot } = props;
  const { isDesktop } = useIsDesktop();
  const fetchContext = useContext(FetchContext);

  const getDefaultCountry = (ifEdit) => {
    let ret;
    if (ifEdit !== null) {
      ret = recordForEdit.price_country;
    } else {
      ret = "US";
    }
    return ret;
  };
  const getDefaultTimezone = (ifEdit) => {
    let ret;
    if (ifEdit !== null) {
      ret = recordForEdit.price_time_zone;
    } else {
      ret = "America/New_York";
    }
    return ret;
  };
  const defaultCountry = countries.filter(
    (item) => item?.isoCode === getDefaultCountry(recordForEdit)
  );
  const [selectedOption, setSelectedOption] = useState(defaultCountry[0]);
  const [inputCountryValue, setInputCountryValue] = useState(""); //inputTimezoneValue
  const [inputTimezoneValue, setInputTimezoneValue] = useState(""); //inputTimezoneValue
  const [defaultTimezone, setDefaultTimezone] = useState(
    timeZones.filter((item) => item?.countryCode === selectedOption?.isoCode)
  );
  const defaultSelectedTimezone = defaultTimezone.filter(
    (item) => item.name === getDefaultTimezone(recordForEdit)
  );
  const [selectedTimezoneOption, setSelectedTimezoneOption] = useState(
    defaultSelectedTimezone[0]
  ); 
  const getOptionLabel = (option) => option.name;
  const getTimezoneLabel = (option) =>
    option.currentTimeFormat ? `GMT ${option.currentTimeFormat}` : "";
  const handleTimezondeChange = (event, newVal) => {
    if (newVal !== null) {
      setSelectedTimezoneOption(newVal);
      values.price_time_zone = newVal.name;
    }
  };
  const onTimezoneInputChange = (event, newVal) => {
    if (newVal !== null) {
      setInputTimezoneValue(newVal);
    }
  };
  const handleCountrysChange = (event, newVal) => {
    if (newVal !== null) {
      setSelectedOption(newVal);
      setDefaultTimezone(
        timeZones.filter((item) => item.countryCode === newVal.isoCode)
      );
      values.price_country = newVal.isoCode;
      values.currency_code = newVal.currency;
      setSelectedTimezoneOption("");
    }
  };
  const onCountryInputChange = (event, newVal) => {
    if (newVal !== null) {
      setInputCountryValue(newVal);
    }
  };
  const initialFValues = {
    id: 0,
    name: "",
    price_country: "US",
    price_time_zone: "America/New_York",
    fee_type: recordForEdit !== null ? recordForEdit.fee_type : "",
    parking_fee_unit:
      recordForEdit !== null ? recordForEdit.parking_fee_unit : "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = /^[a-zA-Z\d\s]*$/.test(fieldValues.name)
        ? ""
        : "Please enter correct price plan name";
    if ("fixed_fee" in fieldValues)
      //temp.fixed_fee = (/^[0-9]*\.?[0-9]{2}$/).test(fieldValues.fixed_fee)  ?  "" : "Please provide correct value for Fixed Fee."
      temp.fixed_fee = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/.test(
        fieldValues.fixed_fee
      )
        ? ""
        : "Please provide correct value for Fixed Fee.";
   if ("auth_amount" in fieldValues) {
  const isValidAuthAmount =
    /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/.test(fieldValues.auth_amount) &&
    parseFloat(fieldValues.auth_amount) > 0;

  temp.auth_amount = isValidAuthAmount
    ? ""
    : "Auth amount must be greater than 0 and in the correct format.";
}
        
    // if ("auth_amount" in fieldValues)
    //   temp.auth_amount = /^\s*(?=.*[1-9])\d*(?:\.\d{1,4})?\s*$/.test(
    //     fieldValues.auth_amount
    //   )
    //     ? ""
    //     : "Please provide correct value for Auth Amount.";
    //temp.auth_amount = (/^(?:[1-9]|[12]\d)\d*(?:\.\d{1,2})?\s*$/).test(fieldValues.auth_amount)  ?  "" : "Please provide correct value for Auth Amount."
    if ("variable_fee" in fieldValues)
      temp.variable_fee = /^\s*(?=.*[1-9])\d*(?:\.\d{1,4})?\s*$/.test(
        fieldValues.variable_fee
      )
        ? ""
        : "Please provide correct value for Variable Fee.";
    if ("parking_fee" in fieldValues)
      //temp.parking_fee = (/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/).test(fieldValues.parking_fee) || fieldValues.parking_fee === "" || fieldValues.parking_fee === null ?  "" : "Please provide correct value for Parking Fee."
      temp.parking_fee =
        /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/.test(fieldValues.parking_fee) ||
        fieldValues.parking_fee === "" ||
        fieldValues.parking_fee === null
          ? ""
          : "Please provide correct value for Parking Fee.";

    // if ('buffer_time' in fieldValues)
    //     temp.buffer_time = (/^(?:[1-9]|[12]\d)\d*$/).test(fieldValues.buffer_time)  ?  null : "Please provide correct value for Buffer Time."
    // if ('buffer_time' in fieldValues)
    //     temp.buffer_time = (/^[^.]*$/).test(fieldValues.buffer_time)  ?  "" : "Please provide correct value for Buffer Time."

    setErrors({
      ...temp,
    });
setScheduleErrors(!Object.values(temp).every((x) => x === ""));

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");

  };

  const [scheduleErrors, setScheduleErrors] = useState(false);

  function convertUTCToLocal(schedule, timezone) {
    const result = [];

    // Mapping of day names to Luxon's weekday values
    const dayToWeekday = {
      Sunday: 7,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    // Helper to calculate the local time and day from UTC
    const convertToLocalWithDay = (time, day, timezone) => {
      const [hours, minutes] = time.split(":").map(Number);

      // Get the corresponding weekday number for the given day
      const weekday = dayToWeekday[day];

      if (!weekday) {
        throw new Error(`Invalid day provided: ${day}`);
      }

      // Create a DateTime object in UTC
      const utcDateTime = DateTime.fromObject(
        { hour: hours, minute: minutes, weekday },
        { zone: "UTC" }
      );

      // Convert to local time
      const localDateTime = utcDateTime.setZone(timezone);

      return {
        time: localDateTime.toFormat("HH:mm"), // Local time
        day: localDateTime.toFormat("cccc"), // Local-adjusted day
      };
    };

    schedule.forEach((entry) => {
      const { id, start_time, end_time, variable_fee, day } = entry;

      // Convert start and end times back to local time
      const startLocal = convertToLocalWithDay(start_time, day, timezone);
      const endLocal = convertToLocalWithDay(end_time, day, timezone);

      if (startLocal.day === endLocal.day) {
        // Both times fall on the same local day
        result.push({
          id,
          start_time: startLocal.time,
          end_time: endLocal.time,
          variable_fee,
          day: startLocal.day, // Local-adjusted day
        });
      } else {
        // Time range spans across two local days
        result.push({
          id,
          start_time: startLocal.time,
          end_time: "23:59",
          flag:true,
          variable_fee,
          day: startLocal.day, // First local day
        });
        result.push({
          id: id + 1, // Assign a new ID for the second entry
          start_time: "00:00",
          end_time: endLocal.time,
          variable_fee,
          day: endLocal.day, // Second local day
        });
      }
    });

    return result;
  }
  const transformToWeekSchedule = (utcSchedule) => {
    const weekSchedule = {};

    utcSchedule.forEach((entry) => {
      const { start_time, end_time, variable_fee, day } = entry;
      if (!weekSchedule[day]) {
        weekSchedule[day] = [];
      }
      weekSchedule[day].push({
        id: entry.id,
          time: `${start_time} - ${end_time}`,
          fee: `$${variable_fee}`,
        });
      });

      return weekSchedule;
  };
  const revertSchedule = (apiSchedule, timeZone) => {
    const utcSchedule = convertUTCToLocal(apiSchedule, timeZone);
    const revertedWeekSchedule = transformToWeekSchedule(utcSchedule);

    // Merge consecutive time slots for each day
    const mergedWeekSchedule = Object.keys(revertedWeekSchedule).reduce(
      (acc, day) => {
        // Sort the slots by start_time for each day
        const sortedSlots = revertedWeekSchedule[day].sort((a, b) => {
          const [aStart] = a.time.split(" - ").map((t) => t.trim());
          const [bStart] = b.time.split(" - ").map((t) => t.trim());
          return aStart.localeCompare(bStart); // Sort by start time
        });

        // Merge consecutive slots
        const mergedSlots = [];
        let currentSlot = sortedSlots[0];

        for (let i = 1; i < sortedSlots.length; i++) {
          const nextSlot = sortedSlots[i];
          const [currentStart, currentEnd] = currentSlot.time
            .split(" - ")
            .map((t) => t.trim());
          const [nextStart, nextEnd] = nextSlot.time
            .split(" - ")
            .map((t) => t.trim());

          // Check if the current slot and next slot are consecutive or within a 1-minute gap
          const currentEndTime = DateTime.fromFormat(currentEnd, "HH:mm");
          const nextStartTime = DateTime.fromFormat(nextStart, "HH:mm");

          // Allow merge if the difference is 1 minute or less AND the fees are the same
          const timeDiff = nextStartTime.diff(
            currentEndTime,
            "minutes"
          ).minutes;

          if (timeDiff <= 1 && currentSlot.fee === nextSlot.fee) {
            // Merge the slots: extend the end time
            currentSlot = {
              ...currentSlot,
              time: `${currentStart} - ${nextEnd}`,
            };
          } else {
            // No merge needed, push the current slot and move to the next
            mergedSlots.push(currentSlot);
            currentSlot = nextSlot;
          }
        }

        // Don't forget to push the last slot
        mergedSlots.push(currentSlot);

        acc[day] = mergedSlots;
        return acc;
      },
      {}
    );

    return mergedWeekSchedule;
  };

  const [weekSchedule, setWeekSchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  useEffect(() => {
    if (recordForEdit) {
      const apiSchedule = recordForEdit?.weekly_variable_fee; // Your data from API
      const revertedSchedule = revertSchedule(
        apiSchedule,
        recordForEdit?.price_time_zone
      );
      setWeekSchedule((prevSchedule) => {
        const updatedSchedule = { ...prevSchedule };

        Object.keys(revertedSchedule).forEach((day) => {
          if (updatedSchedule[day]) {
            updatedSchedule[day] = [
              ...updatedSchedule[day],
              ...revertedSchedule[day],
            ];
          }
        });

        return updatedSchedule;
      });
    }
  }, [recordForEdit])
  

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

  const handleScheduleError = (hasError) => {
    setScheduleErrors(hasError);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   if (scheduleErrors || !validate()) {
     return; // Stop form submission
   }


    if (validate()) {
      const payload = { ...values };
      if (!payload.parking_fee) {
        payload.parking_fee_unit = "NONE";
      }
      addOrEdit(payload, resetForm, weekSchedule);
    } else {
      if (values.parking_fee_unit && values.parking_fee_unit !== "NONE") {
        addOrEdit(values, resetForm, weekSchedule);
      }
    }
  };


  useEffect(() => {
    if (recordForEdit != null){
      // if(recordForEdit.buffer_time && recordForEdit.buffer_time === null ){
      //   recordForEdit.buffer_time = undefined;
      // }
      setValues({
        ...recordForEdit,
      });}
  }, [recordForEdit, setValues]);

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        // style={{ flexGrow: 1, minWidth:'890px' }}
      >
        <Grid container fullWidth>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="name"
              label="Name"
              required
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="occupency_fee"
              label="Occupency Fee ($)"
              required
              //type="number"
              //inputProps={{ min: 0 }}
              value={values.fixed_fee}
              onChange={handleInputChange}
              error={errors.fixed_fee}
              fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={isDesktop ? 6 : 12}>
            <FeeTypeSelect
              name="fee_type"
              label="Fee Type"
              value={values.fee_type}
              error={errors.fee_type}
              required
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={isDesktop ? 6 : 12}>
            <Controls.Input
              name="buffer_time"
              label="Buffer Time (Min)"
              //required
              type="number"
              inputProps={{ min: 0 }}
              value={values.buffer_time}
              onChange={handleInputChange}
              error={errors.buffer_time}
              fullWidth
              style={{ width: "95%" }}
            />
          </Grid>
          <Grid item xs={12}>
            <ContriesSelect
              //name="country"
              options={countries}
              getOptionLabel={getOptionLabel}
              //getOptionDisabled={getOptionDisabled}
              selectedOption={selectedOption}
              onChange={handleCountrysChange}
              //error={errors.country}
              inputValue={inputCountryValue}
              onInputChange={onCountryInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TimezonesSelect
              //name="price_time_zone"
              options={defaultTimezone}
              getOptionLabel={getTimezoneLabel}
              //getOptionDisabled={getOptionDisabled}
              selectedOption={selectedTimezoneOption}
              onChange={handleTimezondeChange}
              //error={errors.price_time_zone}
              inputValue={inputTimezoneValue}
              onInputChange={onTimezoneInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <Controls.Button type="submit" text="Submit" />
              <Controls.Button
                text="Cancel"
                color="default"
                onClick={closeForm}
              />
            </div>
          </Grid>
        </Grid>
      </Form>

      {fetchContext.isAdmin && <WeeklySchedule
        onScheduleError={handleScheduleError}
        weekSchedule={weekSchedule}
        setWeekSchedule={setWeekSchedule}
        setEditslot={setEditslot}
        editslot={editslot}
        handleSubmit={handleSubmit}
      />}
    </>
  );
};

export default PricesForm;
