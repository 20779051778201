import { ImportExport } from '@material-ui/icons';
import { Tooltip } from '@mui/material';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { FetchContext } from '../../context/FetchContext';

const ExportDTEData = ({ filterObject }) => {
  const fetchContext = useContext(FetchContext);

function fetchConvertedDate(dateValue, timezone) {
  const dt = DateTime.fromISO(dateValue.replace(' ', 'T'), { zone: 'UTC' });

  const dtConverted = dt.setZone(timezone);

  // Return date in short format and time in 24-hour format with seconds
  return dtConverted.toLocaleString(DateTime.DATE_SHORT) + ' ' + dtConverted.toFormat('HH:mm:ss');
}

  const [zipFile, setZipFile] = useState(null);

  const columnsForFile1 = [
    'Session ID',
    'Charger ID/number',
    'Number of unique visitors',
    'Energy usage per session',
    'Peak demand per session',
    'Avg demand per session',
    'Connection start time',
    'Connection end time',
    'Charge Duration',
    'Idle Duration',
    'Total fee charged to user',
  ];

  const columnsForFile2 = [
    'Charger ID/number',
    'Interval Start time/date',
    'Interval End time/date',
    'KWH value',
    'Max Kw',
    'Avg Kw',
  ];

  const generateZipFile = (csvData1, csvData2) => {
    const zip = new JSZip();
    zip.file(`RE_SF_C_${Date.now()}.csv`, csvData1.join('\n'));
    zip.file(`RE_IF_EF_C_${Date.now()}.csv`, csvData2.join('\n'));
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      setZipFile(blob);
    });
  };

  const asyncFnComputeData = () => {
    // const dataArr = [];
    let dataArr1 = [columnsForFile1];
    let dataArr2 = [columnsForFile2];
    fetchContext.authAxios.post(
      `/filter-transactions`,
      {
        "from_date": filterObject.from_date ? DateTime.fromJSDate(filterObject.from_date).toFormat('dd-MM-yyyy') : filterObject.from_date,
        "to_date": filterObject.to_date ? DateTime.fromJSDate(filterObject.to_date).toFormat('dd-MM-yyyy') : filterObject.to_date,
        "export": true
      }
    ).then(({ data }) => {
      const pdata = data.data;

      if (pdata) {
        for (let item of pdata) {

          let obj1 = [
            item.id,
            item.ocpp_cbid,
            1,
            item.energy ? item.energy : '',
            item.max_power,
            item.average_power,
            item.session_start ? fetchConvertedDate(item.session_start, item.timezone) : '',
            item.session_end ? fetchConvertedDate(item.session_end, item.timezone) : '',
            item.session_end && item.session_start ? DateTime.fromSQL(item.session_end).diff(DateTime.fromSQL(item.session_start)).toFormat('hh:mm:ss') : '',
            0,
            item.type !== "FREE" && item.amount !== null ? item.amount.toFixed(2) : '0'
          ];
          let obj2 = [
            item.ocpp_cbid,
            item.session_start ? fetchConvertedDate(item.session_start, item.timezone) : '',
            item.session_end ? fetchConvertedDate(item.session_end, item.timezone) : '',
            item.energy ? item.energy : '',
            item.max_power / 1000,
            item.average_power / 1000
          ];
          dataArr1 = [...dataArr1, obj1];
          dataArr2 = [...dataArr2, obj2];
        }

        generateZipFile(dataArr1, dataArr2);
      }
    })
  };

  useEffect(() => {
    if (zipFile) {
      saveAs(zipFile, 'dte_file.zip')
      setZipFile(null);
    }
  }, [zipFile]);

  return (
    <>
      {/* <CsvDownloader
        filename={`RE_SF_C_${Date.now()}`}
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}> */}
      <Tooltip title="Export / Download">
        <ImportExport onClick={asyncFnComputeData} />
      </Tooltip>
      {/* </CsvDownloader> */}
    </>
  );

};

export default ExportDTEData;
