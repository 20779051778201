import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
import useTable from "../../components/useTable";
//import Controls from "../../components/controls/Controls";
// import AddIcon from '@material-ui/icons/Add';
//import Popup from "../../components/Popup";
import Loader from "../../components/Loader";
//import { MoreHorizRounded } from '@material-ui/icons';
// import CloseIcon from '@material-ui/icons/Close';
import ExportDataByGroups from "./ExportDataByGroups";
import ExportDataByGroupsNext from "./ExportDataByGroupsNext";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
  Paper,
  TableBody,
  TableHead,
  TableCell,
  Toolbar,
  Tooltip,
  Typography,
  TableRow,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MonthRangeSelect from "./MonthRangeSelect";
import { Link as RouterLink } from "react-router-dom";
import { DateTime } from "luxon";
import { InfoOutlined } from "@material-ui/icons";
//import { ExpandTable } from '../../components/controls, /ExpandTable';
import { Link, makeStyles } from "@material-ui/core";
// import SessionStatus from './SessionStatus';
//import ActiveSessionsValues from './ActiveSessionsValues';
//import SessionsPopUp from './SessionsPopUp';
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Filter from "./Filter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SessionByChargerGroupsGraph from "./SessionByChargerGroupsGraph";
import Controls from "../../components/controls/Controls";
// import ReportGraph from "./ReportGraph";

const useStyles = makeStyles((theme) => ({
  tblCellInside: {
    fontWeight: "500",
    color: "#fff",
    backgroundColor: "#8a0a0b",
    cursor: "pointer",
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  searchIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    padding: "18px 0",
    [theme.breakpoints.between("sm", "md")]: {
      padding: "18px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: "18px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  filterIcon: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  filterContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "end",
      alignItems: "center",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
  },
  iconAlignment: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  },
  datePicker: {
    display: "inline-block",
    textAlign: "start",
  },
}));

const SessionByChargerGroups = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  //const [recordForEdit, setRecordForEdit] = useState(null)
  const [loaded, setLoaded] = useState(false);
  const dateStr = DateTime.now();
  const selectedMonth = dateStr.toFormat(`01-${"MM-yyyy"}`);
  const [page, setPage] = useState(1);
  const [byMonth, setByMonth] = useState(selectedMonth);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  //const [openPopup, setOpenPopup] = useState(false)
  //const [transactionId, setTransactionId] = useState(0)
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showSearch, setShowSearch] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);
  const [callSecondary, setCallSecondary] = useState(false);
  const [filterObject, setFilterObject] = useState({
    search_keyword: null,
    from_date: null,
    to_date: null,
    range: false,
    // is_search: !showSearch,
    // export: false,
  });

  const headCells = [
    { id: "", label: "" },
    { id: "charger_group_name", label: "Charger Group Name" },
    { id: "energy", label: "Energy (KWH)" },
    { id: "amount", label: "Amount (USD)" },
    { id: "charging_duration", label: "Average Charging Duration (Minutes)" },
    { id: "transactions", label: "Number of Sessions" },
    { id: "", label: "" },
    { id: "", label: "" },
  ];

  const {
    TblContainer,
    //TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page, byMonth, filterObject) => {
    try {
      const { data } = await fetchContext.authAxios.post(
        `/get-report-by-charger-group?page=${page}`,
        {
          date: byMonth,
          export: false,
          ...filterObject,
        }
      );
      setCallSecondary(true)
      //let res = Object.values(data.data);
      setRecords(data.data);
      setTotalRecord(data.totalItems);
      if (data.totalItems && data.totalItems > 20) {
        let count = Math.ceil(data.totalItems / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      setCallSecondary(true)
      console.log("the err", err);
    }
  };

  useEffect(() => {
    getAll(fetchContext, page, byMonth, filterObject);
  }, [fetchContext, page]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  // const openInPopup = item => {
  //     setTransactionId(item)
  //     setOpenPopup(true)
  // }

  const changeSelectOptionHandler = (event) => {
    let report_month = event.target.value;
    setLoaded(false);
    setByMonth(report_month);
    setShowFilter(true);
    setPage(1);
    getAll(fetchContext, 1, report_month, {
      ...filterObject,
      from_date: null,
      to_date: null,
      range: false,
    });
    setFilterObject({
      ...filterObject,
      from_date: null,
      to_date: null,
      range: false,
    });
    //setSelected(event.target.value);
  };

  const onShowHideSearch = () => {
    setShowSearch(!showSearch);
    setShowFilter(true);
    setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1, byMonth, { search_keyword: null, range: false });
    setFilterObject({
      search_keyword: null,
      range: false,
    });
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
  };

  const onFilterSubmit = (values) => {
    setPage(1);
    let updatedValues = {
      ...values,
      from_date: values.from_date
        ? DateTime.fromJSDate(values.from_date).toFormat("dd-MM-yyyy")
        : null,
      to_date: values.to_date
        ? DateTime.fromJSDate(values.to_date).toFormat("dd-MM-yyyy")
        : null,
      range: !showSearch
        ? false
        : values.from_date && values.to_date
        ? true
        : false,
      date: byMonth,
    };
    getAll(fetchContext, page, byMonth, updatedValues);
    setFilterObject(updatedValues);
  };

  const onShowHideFilter = () => {
    setShowFilter(!showFilter);
    setShowSearch(true);
    getAll(fetchContext, 1, byMonth, {
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
    setFilterObject({
      ...filterObject,
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
  };

  const onShowHideSearchCancle = () => {
    setShowSearch(true);
    setShowFilter(true);
    // setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1, byMonth, {
      search_keyword: null,
      from_date: null,
      to_date: null,
      range: false,
    });
    setFilterObject({
      search_keyword: null,
      is_search: !showSearch,
      from_date: null,
      to_date: null,
      range: false,
    });
  };

  const handleCallbackData = () => {};

  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Sessions by Charger Groups
            </Typography>
          </div>

          {fetchContext.isAdmin && (
            <div className={classes.searchIcon} onClick={onShowHideSearch}>
              {showSearch ? (
                <Tooltip title="Search">
                  <SearchIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Remove Search">
                  <SearchOffIcon />
                </Tooltip>
              )}
            </div>
          )}
          <div className={classes.filterIcon} onClick={onShowHideFilter}>
            {!showFilter ? (
              <Tooltip title="Filter">
                <FilterAltOffIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Filter">
                <FilterAltIcon />
              </Tooltip>
            )}
          </div>
          <div className={classes.newButton}>
            <MonthRangeSelect
              label="Filter by Month"
              name="report_month"
              value={byMonth}
              //error={errors.charger_type}
              required
              onChange={changeSelectOptionHandler}
            />
          </div>
        </Toolbar> */}
        <div className={classes.headerContainer}>
          <div className={classes.pageTitle}>Sessions by Charger Groups</div>
          <div className={classes.filterContainer}>
            <div className={classes.iconAlignment}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
              <div onClick={onShowHideFilter}>
                <div className={classes.filterIcon}>
                  {showFilter ? (
                    <Tooltip title="Filter">
                      <FilterAltIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Filter">
                      <FilterAltOffIcon />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.datePicker}>
              <div className={classes.newButton}>
                <MonthRangeSelect
                  label="Filter by Month"
                  name="report_month"
                  value={byMonth}
                  //error={errors.charger_type}
                  required
                  onChange={changeSelectOptionHandler}
                />
              </div>
            </div>
          </div>
        </div>
        {!showSearch && (
          <Filter
            showSearch={showSearch}
            setFilterObject={setFilterObject}
            onFilter={onFilter}
            filterObject={filterObject}
            oncancle={onShowHideSearchCancle}
          />
        )}
        {!showFilter && (
          <Filter
            showSearch={showSearch}
            setFilterObject={setFilterObject}
            onFilter={onFilter}
            filterObject={filterObject}
            oncancle={onShowHideSearchCancle}
          />
        )}
        <SessionByChargerGroupsGraph
          byMonth={byMonth}
          filterObject={filterObject}
          callSecondary={callSecondary}
          setCallSecondary={setCallSecondary}
        />
        <TblContainer>
          {/* <TblHead /> */}
          <TableHead>
            <TableRow>
              <TableCell className={classes.tblCellInside} />
              <TableCell className={classes.tblCellInside}>
                Charger Group Name
              </TableCell>
              <TableCell className={classes.tblCellInside}>
                Energy (KWH)
              </TableCell>
              <TableCell className={classes.tblCellInside}>
                Amount (USD)
              </TableCell>
              <TableCell className={classes.tblCellInside}>
                Average Charging Duration (Minutes)
              </TableCell>
              <TableCell className={classes.tblCellInside}>
                Number of Sessions
              </TableCell>
              <TableCell className={classes.tblCellInside}>
                {records?.length > 0 && (
                  <ExportDataByGroups
                    filterDate={filterObject}
                    byMonth={byMonth}
                  />
                )}
              </TableCell>
              <TableCell className={classes.tblCellInside} />
            </TableRow>
          </TableHead>
          {loaded ? (
            <TableBody>
              {records.map((item, index) => (
                <TableRow key={index}>
                  <TableCell />
                  <TableCell>{item.charger_group_name}</TableCell>
                  <TableCell>{Number(item.energy).toFixed(2)}</TableCell>
                  <TableCell>{Number(item.amount).toFixed(2)}</TableCell>
                  <TableCell>
                    {item?.timezone_duration
                      ? (item.timezone_duration / 60 / item.total).toFixed(2)
                      : "-"}
                  </TableCell>
                  <TableCell>{item.total}</TableCell>
                  <TableCell>
                    <Tooltip title="View More">
                      <Link
                        component={RouterLink}
                        to={`/reports-charger-groups-next/${
                          item.charger_group_id
                        }/${
                          filterObject?.to_date && filterObject?.from_date
                            ? `${filterObject?.from_date}to${filterObject?.to_date}`
                            : byMonth
                          // byMonth
                        }`}
                      >
                        <InfoOutlined />
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <ExportDataByGroupsNext
                      id={item.charger_group_id}
                      filterDate={filterObject}
                      bymonth={byMonth}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
            //shape="rounded/circular"
          />
        </Stack> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography spacing={2} style={{ color: "#8a0a0b" }} component="div">
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default SessionByChargerGroups;
