import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import rightMark from "../../assets/images/right-mark.png";
import Controls from "../../components/controls/Controls";
import { useForm } from "../../components/useForm";
import { Typography } from "@material-ui/core";
import { Link } from "@mui/material";
import { useHistory } from "react-router-dom";
import { publicFetch } from "../../services/api";
import Notification from "../../components/Notification";

const ForgetPasswordForm = () => {
  const history = useHistory();
  const [page, setPage] = useState("forgotPassword");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const initialFValues = {
    type_email_123: null,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("type_email_123" in fieldValues) {
      temp.type_email_123 = /.+@.+\..+/.test(fieldValues.type_email_123)
        ? ""
        : "The email must be a valid email address.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };
  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleForgotPasswordSubmit = async () => {
    try {
      setLoading(true);
      if (values?.type_email_123 !== "") {
        if (/.+@.+\..+/.test(values.type_email_123)) {
          const { data } = await publicFetch.post("/send-otp", {
            email: values.type_email_123,
          });
          if (data?.status) {
            setNotify({
              isOpen: true,
              message: `Your OTP has been sent to your email. Please check your inbox.`,
              type: "success",
            });
          setPage("otp");
          } else {
            setNotify({
              isOpen: true,
              message: data?.data,
              type: "error",
            });
          }
          setLoading(false);
        } else {
          setErrors({
            type_email_123: "The email must be a valid email address.",
          });
          setLoading(false);
        }
      } else {
        setErrors({
          type_email_123: "Please enter your email",
        });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("err sending OTP:", err);
      setErrors({
        type_email_123: "Email id is not registered",
      });
      // setNotify({
      //   isOpen: true,
      //   message: "Failed to send OTP. Please try again.",
      //   type: "error",
      // });
    }
  };

  const resendotp = async () => {
    try {
      setLoading(true);
      const { data } = await publicFetch.post("/resend-otp", {
        email: values.type_email_123,
      });
      setLoading(false);
      setPage("otp");
      if (data?.status === true) {
        setNotify({
          isOpen: true,
          message: `OTP Resend Successfully`,
          type: "success",
        });
      }
    } catch (err) {
      setLoading(false);
      console.log("err sending OTP:", err);
    }
  };

  const handleOtpSubmit = async (event) => {
    if (otp === "") {
      setError("Please Enter OTP");
      return;
    }
    event.preventDefault();
    try {
      setLoading(true);
      const { data } = await publicFetch.post("/verify-otp", {
        email: values.type_email_123,
        otp,
      });

      setLoading(false);
      if (data?.status === true) {
        setPage("resetPassword");
      } else {
        setNotify({
          isOpen: true,
          message: `${data?.data}`,
          type: "error",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error verifying OTP:", error);
      setNotify({
        isOpen: true,
        message: "Invalid OTP. Please try again.",
        type: "error",
      });
    }
  };

  const validatePassword = (password) => {
    const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasCapitalLetter = /[A-Z]/.test(password);
    const isLengthValid = password ? password.length >= 8 : false;

    return (
      hasSpecialCharacter && hasNumber && hasCapitalLetter && isLengthValid
    );
  };
  const handleResetPasswordSubmit = async (event) => {
    event.preventDefault();

    // Check if passwords match
    if (newPassword !== reenterPassword) {
      setError("Passwords do not match");
      return;
    }

    // Validate the new password
    if (!validatePassword(newPassword)) {
      setError(
        "Password must contain at least one special character, one numeric digit, one uppercase letter, and be at least 8 characters long."
      );
      return;
    }

    try {
      setLoading(true);
      const { data } = await publicFetch.post("/reset-password", {
        email: values.type_email_123,
        password: newPassword,
        password_confirmation: reenterPassword,
      });
      setLoading(false);
      setPage("passwordUpdated");
    } catch (error) {
      setLoading(false);
      console.error("Error resetting password:", error);
      setNotify({
        isOpen: true,
        message: "Failed to reset password. Please try again.",
        type: "error",
      });
    }
  };

  const handleLoginClick = () => {
    history.push("/"); // Update this line to navigate to your login page
  };

  return (
    <div className="container">
      {page === "forgotPassword" && (
        <div className="col-md-6 loginwindow2 text-center m-auto">
          <div className="logocenter">
            <img src={logo} alt="Logo" className="jss4" />
          </div>
          <div className="forgothead">
            <h3>Forgot Password</h3>
          </div>
          <div className="forgotinput">
            <Controls.Input
              required
              label="Email"
              name="type_email_123"
              value={values.type_email_123}
              onChange={handleInputChange}
              autoComplete="off"
              error={errors.type_email_123}
              style={{ width: "97%", paddingTop: 0 }}
            />
            <div className="ipuntinst">
              <h4>Please Enter your email to reset your password</h4>
            </div>
          </div>
          <div className="sumbitbtn">
            <input
              name="Submit"
              value="Submit"
              type="button"
              className="redbtn"
              onClick={handleForgotPasswordSubmit}
              disabled={loading}
            />
          </div>
          <Typography className="textcolour pt-2">
            <Link href="/">Login ?</Link>
          </Typography>
        </div>
      )}

      {page === "otp" && (
        <div className="col-md-6 loginwindow2 text-center m-auto">
          <div className="logocenter">
            <img src={logo} alt="Logo" className="jss4" />
          </div>
          <div className="forgothead">
            <h3>OTP</h3>
          </div>
          <div className="forgotinput">
            <Controls.Input
              required
              label="OTP"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              autoComplete="off"
              style={{ width: "97%", paddingTop: 0 }}
            />
            <div className="ipuntinst errortext">
              <h4 style={{ color: "#fd1d1d" }}>{error}</h4>
            </div>
            <div className="ipuntinst">
              <h4>Please enter OTP</h4>
            </div>
          </div>
          <div
            className="d-flex justify-content-end p-0 m-0"
            onClick={() => resendotp()}
          >
            <p className="textcolour p-0 m-0">Resend OTP ?</p>
          </div>
          <div className="sumbitbtn">
            <input
              name="Submit"
              value="Submit"
              type="button"
              className="redbtn"
              onClick={handleOtpSubmit}
              disabled={loading}
            />
          </div>
          <Typography className="textcolour pt-2">
            <Link href="/">Login ?</Link>
          </Typography>
        </div>
      )}

      {page === "resetPassword" && (
        <div className="col-md-6 loginwindow2 text-center m-auto">
          <div className="logocenter">
            <img src={logo} alt="Logo" className="jss4" />
          </div>
          <div className="forgothead">
            <h3>Forgot Password</h3>
          </div>
          <div className="forgotinput">
            <Controls.Input
              required
              label="New Password"
              name="newPassword"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setError("");
              }}
              type="password"
              autoComplete="off"
              style={{ width: "97%", paddingTop: 0 }}
            />
            <div className="ipuntinst errortext">
              <h4 style={{ color: "#fd1d1d" }}>{error}</h4>
            </div>
          </div>
          <div className="forgotinput">
            <Controls.Input
              required
              label="Reenter Password"
              name="reenterPassword"
              value={reenterPassword}
              onChange={(e) => {
                setReenterPassword(e.target.value)
                setError("");
              }}
              type="password"
              autoComplete="off"
              style={{ width: "97%", paddingTop: 0 }}
            />
            <div className="ipuntinst errortext">
              <h4 style={{ color: "#fd1d1d" }}>{error}</h4>
            </div>
          </div>
          <div className="sumbitbtn">
            <input
              name="Submit"
              value="Submit"
              type="button"
              className="redbtn"
              onClick={handleResetPasswordSubmit}
              disabled={loading}
            />
          </div>
          <Typography className="textcolour pt-2">
            <Link href="/">Login ?</Link>
          </Typography>
        </div>
      )}

      {page === "passwordUpdated" && (
        <div className="col-md-6 loginwindow2 text-center m-auto">
          <div className="logocenter">
            <img src={logo} alt="Logo" className="jss4" />
          </div>
          <div className="forgothead text-center">
            <h3>
              <img src={rightMark} alt="Right Mark" />
            </h3>
          </div>
          <div className="updatemessage">
            Password Updated!
            <h4>Your password has been changed successfully</h4>
          </div>
          <div className="sumbitbtn">
            <input
              name="Submit"
              value="Login Here"
              type="button"
              className="redbtn"
              onClick={handleLoginClick}
              disabled={loading}
            />
          </div>
        </div>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default ForgetPasswordForm;
