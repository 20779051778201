import { Stack, Typography } from '@mui/material';
//import type { StackProps } from '@mui/material';
import { DateTime as BaseDateTime } from 'luxon';
import { useMemo } from 'react';
//import type { VoidFunctionComponent } from 'react';

// export interface DateTimeProps {
//   dateTime: string;
//   disableDate?: boolean;
//   disableTime?: boolean;
//   disableTimezone?: boolean;
//   long?: boolean;
//   sx?: StackProps['sx'];
//   timezone?: string;
// }

const TimeZone = ({ dateTime, disableDate = false, disableTime = false, disableTimezone = false, long = false, sx, timezone }) => {

  // const format = useMemo(() => {
  //   if (disableDate) {
  //     return BaseDateTime.TIME_WITH_SECONDS;
  //   }
  //   if (disableTime) {
  //     return long ? BaseDateTime.DATE_FULL : BaseDateTime.DATE_SHORT;
  //   }
  //   return long ? { ...BaseDateTime.DATETIME_FULL_WITH_SECONDS, timeZoneName: undefined } : BaseDateTime.DATETIME_SHORT_WITH_SECONDS;
  // }, [ disableDate, disableTime, long ]);

  const localizedDateTime = useMemo(() => {
    //const dateTimeInstance = BaseDateTime.fromISO(dateTime);  to be used with format like: 2022-06-10T17:24:08.000Z
    //const dateTimeInstance = BaseDateTime.fromSQL(dateTime);  to be used with format like: 2022-06-10 16:46:55
    const fDate = dateTime?.split(' ');
    const formattedDateTimeInUTC = `${fDate && fDate[0]}T${fDate &&fDate[1]}.000Z`;
    const dateTimeInstance = BaseDateTime.fromISO(formattedDateTimeInUTC);

    return dateTimeInstance.isValid
      ? timezone ? dateTimeInstance.setZone(timezone) : dateTimeInstance
      : undefined;
  }, [ dateTime, timezone ]);

  if ((disableDate && disableTime) || !localizedDateTime) {
    return null;
  }

  return (
    <Stack sx={sx}>
      {/* <Typography variant="inherit">{localizedDateTime.toLocaleString(format)}</Typography> */}
      <Typography variant="inherit">{localizedDateTime.toFormat('MM-dd-yyyy HH:mm:ss')}</Typography>
      {!disableTimezone && <Typography color="textSecondary" component="div" variant="caption">{localizedDateTime.offsetNameLong}</Typography>}
    </Stack>
  );

  
};

export default TimeZone;
