import React, { useContext, useState } from "react";
//import { DateTime } from 'luxon';
import CsvDownloader from "react-csv-downloader";
import { ImportExport } from "@material-ui/icons";
import { CircularProgress, Snackbar, Tooltip } from "@mui/material";
import { FetchContext } from "../../context/FetchContext";
import { DateTime } from "luxon";

const UpTimeReportExport = ({ filterObject, byMonth }) => {
  const fetchContext = useContext(FetchContext);

  const [loading, setLoading] = useState(false); // State to manage loading
  const [showMessage, setShowMessage] = useState(false); // State to manage message display
  const asyncFnComputeData = async () => {

setLoading(true); // Set loading to true before fetching data
    setShowMessage(true); // Show message when starting download
    let curStr = DateTime.now();
    let dateParam;
    let selMonthStr = byMonth.split("-");
    let selMonth = Number(selMonthStr[1]);
    if (selMonth === curStr.month) {
      dateParam = curStr.toFormat("dd-MM-yyyy");
    } else {
      dateParam = byMonth;
    }
    const dataArr = [];

    const finalPayload = {
      ocpp_cbid: filterObject?.ocpp_cbid,
      from_date: filterObject?.from_date,
      to_date: filterObject?.to_date,
      export: true,
      range: filterObject?.range,
      with_max: filterObject?.with_max,
      date: dateParam,
    };

    await fetchContext.authAxios
      .post(`/get-up-time-report`, finalPayload)
      .then(({ data }) => {
        const pdata = data.data;
        if (pdata) {
          for (let item of pdata) {
            //dataObj.sessionID = item.id;
            let obj = {
              charger_id: item?.charger_id,
              uptimes: item?.uptimes,
            };
            dataArr.push(obj);
          }
          setLoading(false); // Set loading to false after fetching data
          setShowMessage(false); // Show message when starting download
        }
      });
    return Promise.resolve(dataArr);
  };

  const columns = [
    { id: "charger_id", displayName: "charger id" },
    { id: "uptimes", displayName: "uptimes" },
  ];

  return (
    <>
      <CsvDownloader
        filename="UptimeReportDetails"
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          {loading ? <CircularProgress size={24} /> : <ImportExport />}
        </Tooltip>
      </CsvDownloader>

      {/* Snackbar to display message */}
      <Snackbar
        open={showMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position at top center
        style={{ backgroundColor: "transperent" }} // Customize color to red
        message="Downloading data..."
      />
    </>
  );
};

export default UpTimeReportExport;
