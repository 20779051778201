import { CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Controls from "../../components/controls/Controls";
import { FetchContext } from '../../context/FetchContext';

const PricePlanSelectDC = (props) => {
  const {recordForEdit, value = '', error = null, onChange, siteGroupSelected = null } = props;
  const fetchContext = useContext(FetchContext);
  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);

  const [currentPricePlan, setCurrentPricePlan] = useState(value);

  useEffect(() => {
    const getAllSites = async () => {

      try {
        let specificIds = [431, 432, 433, 434, 435, 387, 75];
        const { data } = await fetchContext.authAxios.get(
          "/select-price-plans"
        );
        if (data.data && data.data.length > 0) {
          let filteredData = data?.data.filter((item) => {
            return specificIds.includes(recordForEdit) || item?.weekly_plan === null;
          });
          let temp = [{ id: "none", name: "None" }, ...filteredData];
          setValues(temp);
          setLoaded(true);
        }
      } catch (err) {
        console.log('the err', err);
      }
    };

    getAllSites(fetchContext);

  }, [fetchContext]);

  useEffect(() => {
    if (siteGroupSelected.price_plan_id) {
      setCurrentPricePlan(siteGroupSelected.price_plan_id)
    }
    if (value) {
      setCurrentPricePlan(value);
    }
  }, [siteGroupSelected, value]);

  return (
    <>
      {loaded
        ?
        <Controls.Select
          name="price_plan_id"
          label="Price Plan DC"
          value={currentPricePlan}
          // value={value}
          //required={true}
          onChange={onChange}
          options={values}
          error={error}
          style={{ width: "100%" }}
        />
        :
        // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>}  />
        <Controls.Input name="price_plan_id" label="Price Plan DC" style={{ width: "97.5%" }}><CircularProgress size="1rem" /> </Controls.Input>
      }

    </>

  )
}

export default PricePlanSelectDC;
