import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import { FetchContext } from "../../context/FetchContext";
import PricePlanSelectAC from "../Sites/PricePlanSelectAC";
import PricePlanSelectDC from "../Sites/PricePlanSelectDC";
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  responsieScreen: {
    [theme.breakpoints.between("xs", "sm")]: {
      width: "auto",
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "600px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "890px",
    },
  },
}));

const SiteGroupForm = (props) => {
  const { addOrEdit, recordForEdit, closeForm } = props;
  const classes = useStyles();

  const initialFValues = {
    id: 0,
    name: "",
    tax_detail_id: [],
    status: false,
  };

  const fetchContext = useContext(FetchContext);
  const [optionValues, setOptionValues] = useState([]);
  //const [ defaultOptionValues, setDefaultOptionValues ] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [editDefaultOptions, setEditDefaultOptions] = useState([]);
  const getOptionSiteLabel = (option) => option.label;
  const getOptionDisabled = (option) => option.value === 1;
  const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(optionValues);
    } else {
      handleClearOptions();
    }
  };

  // useEffect(() => {
  //   const getAllTaxRates = async () => {
  //     // try {
  //     //   const { data } = await fetchContext.authAxios.get("/select-tax-details");
  //     //   const optionSites = [];
  //     //   for (const item of data) {
  //     //     const optObj = { value: item.id, label: item.name };
  //     //     optionSites.push(optObj);
  //     //   }
  //     //   setOptionValues(optionSites);
  //     //   if (recordForEdit != null) {
  //     //     let taxRatesIds = recordForEdit.tax_details;
  //     //     //let chargingStationIds = [6, 7];
  //     //     let defaultOptions = optionSites.filter((item) => taxRatesIds.some((taxIds) => item.value === taxIds.id));
  //     //     setSelectedOptions(defaultOptions);
  //     //     setEditDefaultOptions(defaultOptions);
  //     //   }
  //     // } catch (err) {
  //     //   console.log("the err", err);
  //     // }
  //   };

  //   getAllTaxRates(fetchContext);
  // }, [fetchContext, recordForEdit]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    //https://stackoverflow.com/questions/13283470/regex-for-allowing-alphanumeric-and-space
    // if ('name' in fieldValues)
    //     temp.name = fieldValues.name ? "" : "This field is required."
    // /^[a-zA-Z\d\-_\s]*$/ for AlphaNumeric with space  '-' and '_'
    // /^[a-zA-Z\d\s]*$/ for AlphaNumeric with space
    if ("name" in fieldValues) temp.name = /^[a-zA-Z\d\s]*$/.test(fieldValues.name) ? "" : "Please enter correct site group name";

    setErrors({
      ...temp,
    });
    if (fieldValues === values) return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      Object.assign(values, { tax_detail_id: [] });
      selectedOptions.forEach((item) => {
        values.tax_detail_id.push(item.value);
      });

      if (values.price_plan === null && values.price_plan_id === null) {
        delete values.price_plan;
        delete values.price_plan_id;
      }

      if (values.ac_price_plan === null && values.ac_price_plan_id === null) {
        delete values.ac_price_plan;
        delete values.ac_price_plan_id;
      }

      if (editDefaultOptions.length === 0 && selectedOptions.length === 0) {
        delete values.tax_details;
        delete values.tax_detail_id;
      }
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit, setValues]);

  return (
    <Form onSubmit={handleSubmit} className={classes.responsieScreen}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Controls.Input
            name="name"
            label="Site Account Name"
            required
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
            //fullWidth
            style={{ width: "97.2%" }}
          />
        </Grid>

        {/* <Grid item xs={12} >
                    <PricePlanSelectDC
                        value={values.price_plan_id}
                        error={errors.price_plan_id}
                        //required
                        siteGroupSelected={values}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} >
                    <PricePlanSelectAC
                        value={values.ac_price_plan_id}
                        error={errors.ac_price_plan_id}
                        //required
                        siteGroupSelected={values}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Controls.MultiSelect
                        items={optionValues}
                        getOptionLabel={getOptionSiteLabel}
                        getOptionDisabled={getOptionDisabled}
                        selectedValues={selectedOptions}
                        label={'Select Tax Rates'}
                        placeholder={'Placeholder for Tax Rates'}
                        selectAllLabel="Select all"
                        onToggleOption={handleToggleOption}
                        onClearOptions={handleClearOptions}
                        onSelectAll={handleSelectAll}
                    />
                </Grid>

                <Grid item xs={12} >
                    <Controls.Checkbox
                        name="status"
                        label="Visible"
                        value={values.status}
                        onChange={handleInputChange}
                    />
                </Grid> */}
        <Grid item xs={12}>
          {" "}
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Cancel" color="default" onClick={closeForm} />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default SiteGroupForm;
