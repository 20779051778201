import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Link,
} from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import { AuthContext } from "../../context/AuthContext";
import { publicFetch } from "../../services/api";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logoicon from "../../assets/images/logoicon.png";
import ydot from "../../assets/images/ydot.png";
//import loginBg from '../../assets/images/login-bg.jpg';
//import { TrendingUpRounded } from '@material-ui/icons';
import Notification from "../../components/Notification";
import Popup from "../../components/Popup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
const useStyles = makeStyles((theme) => ({
  root: {
    // [theme.breakpoints.up('lg')]: {
    //   backgroundImage: `url(${loginBg})`,
    // },
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "top left",
    //backgroundColor: '#fff',
    //height: '100vh',
  },
  container: {
    //paddingTop: theme.spacing(6),
    //marginBottom: theme.spacing(6),
    //border: '#a31112',
    width: "390px",
    //height: 'auto',
  },
  disableReveal: {
    "&::-ms-reveal": {
      display: "none",
    },
  },
  logo: {
    display: "block",
    margin: `${theme.spacing(1)}px auto`,
    maxWidth: 100,
  },
  // paperStyle: {
  //   backgroundImage: `url(${loginBg})`,
  //   [theme.breakpoints.up('sm')]: {
  //     backgroundImage: `url(${loginBg})`,
  //   },
  //   backgroundRepeat: "no-repeat",
  //   backgroundPosition: "top left",
  //   backgroundColor: '#e7e7e7',
  //   height: '100vh',
  // }
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const authContext = useContext(AuthContext);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loginSuccess, setLoginSuccess] = useState("");
  const [loginError, setLoginError] = useState("");
  const [redirectOnLogin, setRedirectOnLogin] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const initialFValues = {
    type_email_123: null,
    type_pass_123: null,
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("type_email_123" in fieldValues)
      temp.type_email_123 = /$^|.+@.+..+/.test(fieldValues.type_email_123)
        ? ""
        : "Email is not valid.";
    if ("type_pass_123" in fieldValues)
      temp.type_pass_123 =
        fieldValues.type_pass_123.length !== 0 ? "" : "Password is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    //setValues,
    errors,
    setErrors,
    handleInputChange,
    //resetForm
  } = useForm(initialFValues, true, validate);

  const submitCredentials = async (credentials) => {
    try {
      setLoginLoading(true);
      const { data } = await publicFetch.post(`/login`, {
        email: credentials?.type_email_123,
        password: credentials?.type_pass_123,
      });
      authContext.setAuthState(data);
      return data;
    } catch (error) {
      setLoginLoading(false);
      setLoginError("Invalid username or password, please try again."); //
      setLoginSuccess(null);
      setNotify({
        isOpen: true,
        //message: error.message,
        //message: loginError, // not able to read
        message: "Invalid username or password, please try again.",
        type: "error",
      });
    }
  };

  const handleSubmit = async (value) => {
    value.preventDefault();
    if (validate() && token) {
      finalSubmit();
    }
  };

  const finalSubmit = async () => {
    const temp = await submitCredentials(values);
    if (temp) {
      setLoginSuccess("This is success");
      setLoginError(null);
      setRedirectOnLogin(true);
      history.push("/dashboard");
    }
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("login");
    setToken(token);
  }, [executeRecaptcha]);
  
  const forgetpassword = () =>{
    history.push("/forget-password");
  }

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className="col-md-7 loginleftbg">
          <div className="loginleftlogo">
            <img src={logoicon} alt="Login" />
          </div>
          <div>
            <p className="loginheadtext">
              AN EV <br /> CHARGING <br /> NETWORK <br /> THAT'S ALWAYS <br />
              <span> READY. </span>
            </p>
          </div>
          <p className="loginsmalltext">
            <span>RED E </span> WAS BUILT BY EV DRIVERS FOR EV DRIVERS WITH THE
            AIM OF MAKING CHARGING AS FAST, EASY, AND TRANSPARENT AS POSSIBLE
          </p>
          <div className="ydot">
            <img src={ydot} alt="Login" />
          </div>
        </div>
        <div className="col-md-5 loginwindow text-center">
          {/* <Paper className={classes.root} > */}
          <Container maxWidth="xs" className={classes.container}>
            <Grid align="center">
              <img src={logo} alt="Logo" className={classes.logo} />
            </Grid>
            <Grid align="center">
              <Form onSubmit={handleSubmit} loginScreen={true}>
                <Controls.Input
                  required
                  label="Email"
                  name="type_email_123"
                  value={values.type_email_123}
                  onChange={handleInputChange}
                  // autoComplete={"off"}
                  autoComplete="new-email" 
                  error={errors.type_email_123}
                  style={{ width: "97%", paddingTop: 0 }}
                />
                <Controls.Input
                  required
                  label="Password"
                  type="password"
                  name="type_pass_123"
                  value={values.type_pass_123}
                  // autoComplete={"off"}
                  autoComplete="new-password" 
                  onChange={handleInputChange}
                  error={errors.type_pass_123}
                  style={{ width: "97%" }}
                />
                <Controls.Button
                  style={{ width: "99%", color: "#fff" }}
                  text="Login"
                  variant="contained"
                  type="submit"
                />
              </Form>

              <Typography onclick={forgetpassword} className="textcolour pt-2">
                <Link href="/forget-password"  >Forgot password ?</Link>
              </Typography>
            </Grid>
          </Container>
          <Notification notify={notify} setNotify={setNotify} />
          <div className="bottomtext2">
            <div>
              <p className="loginheadtext">
                AN EV <br /> CHARGING <br /> NETWORK <br /> THAT'S ALWAYS <br />
                <span> READY. </span>
              </p>
            </div>
            <p className="loginsmalltext">
              <span>RED E </span> WAS BUIT BY EV DRIVERS FOR EV DRIVERS WITH THE
              AIM OF MAKING CHARGING AS FAST, EASY, AND TRANSPARENT AS POSSIBLE
            </p>
            <div className="ydot">
              <img src={ydot} alt="Login" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
