import { Table, TableCell, TableRow } from '@material-ui/core';
import ReactECharts from 'echarts-for-react';
import React, { useContext, useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { FetchContext } from '../../context/FetchContext';

function transformData(inputData) {
  const transformedData = [];

  // Helper function to add or update an entry in the transformedData array
  const addOrUpdateEntry = (entryName, status, value) => {
    const existingEntry = transformedData.find(
      (entry) => entry.name === entryName
    );
    if (existingEntry) {
      existingEntry[status === "FINISHED" ? "Finished" : "Failed"] += value;
    } else {
      transformedData.push({
        name: entryName,
        Finished: status === "FINISHED" ? value : 0,
        Failed: status === "FAILED" ? value : 0,
      });
    }
  };

  Object.keys(inputData.data).forEach((status) => {
    if (status !== "ACTIVE") {
      const statusData = inputData.data[status];

      Object.keys(statusData).forEach((transactionType) => {
        const transactionTypeData = statusData[transactionType];

        if (
          transactionType === "FREE" ||
          transactionType === "NAYAX" ||
          transactionType === "RFID" || 
          transactionType === "REDEMAGTEK" ||
          transactionType === "PAYTER"
        ) {
          addOrUpdateEntry(transactionType, status, transactionTypeData);
        } else {
          Object.keys(transactionTypeData).forEach((subType) => {
            if (subType === "OCPI") {
              Object.keys(transactionTypeData[subType]).forEach(
                (ocpiSubType) => {
                  const ocpiValue = transactionTypeData[subType][ocpiSubType];
                  addOrUpdateEntry(
                    `OCPI-${ocpiSubType}`,
                    status,
                    ocpiValue
                  );
                }
              );
            } else {
              addOrUpdateEntry(subType, status, transactionTypeData[subType]);
            }
          });
        }
      });
    }
  });

  return transformedData.filter(item => item.name !== "OTHERS");
}

const SessionsPie = (props) => {
  const { graphType, filterData, handleCallbackData, dataChart } = props

  const [totalFailed, setTotalFailed] = useState(0);
  const [totalFinished, setTotalFinished] = useState(0);

  const fetchContext = useContext(FetchContext);
  const [sessionsCountData, setSessionsCountData] = useState(null);
  const [dataMain, setDataMain] = useState([]);

  const getOption = () => {
    const commonTextStyle = {
      color: '#000',
      fontFamily: 'Aldrich',
    };
    const labelTextStyle = {
      fontFamily: 'Aldrich',
    };

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        textStyle: commonTextStyle
      },
      legend: {
        textStyle: commonTextStyle
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          textStyle: commonTextStyle
        }
      },
      yAxis: {
        type: 'category',
        data: dataMain ? dataMain.map(item => item.name) : [],
        axisLabel: {
          textStyle: commonTextStyle
        }
      },
      series: [
        {
          name: 'Finished',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
            textStyle: labelTextStyle,
          },
          emphasis: {
            focus: 'series'
          },
          itemStyle: {
            color: '#00CC00',
          },
          data: dataMain ? dataMain.map(item => item.Finished) : []
        },
        {
          name: 'Failed',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
            textStyle: labelTextStyle,
          },
          itemStyle: {
            color: '#FF0000',
          },
          emphasis: {
            focus: 'series'
          },
          data: dataMain ? dataMain.map(item => item.Failed) : []
        },
      ],
    };
  };

  const getAllSessionCountData = async (fetchContext, value) => {
    let payload = {}
    if (graphType === null) {
      payload = {
        // ...value,
        ...filterData
        // from_date: formatDateToDDMMYYYY(values.start_date),
        // to_date: formatDateToDDMMYYYY(values.end_date),
      }
    } else {
      payload = value
    }


    try {
      const { data } = await fetchContext.authAxios.post('/pie-chart', payload);
      setSessionsCountData(data?.data);

      // console.log(data)

      // setDataMain(data.data);
      setDataMain(transformData(data && data || []));
      handleCallbackData(transformData(data && data|| []));
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  };

  useEffect(() => {
    getAllSessionCountData(fetchContext, { 'graph_type': graphType });
  }, [fetchContext, graphType, filterData]);

  const dummyData = [
    {
      "name": "Ford",
      "Finished": 3207,
      "Failed": 1573
    },
    {
      "name": "OCPI-Vikas",
      "Finished": 3207,
      "Failed": 1573
    },
    {
      "name": "QR",
      "Finished": 5207,
      "Failed": 1573
    },
    {
      "name": "iOS",
      "Finished": 5207,
      "Failed": 1573
    },
    {
      "name": "Enterprise",
      "Finished": 5207,
      "Failed": 1573
    },
    {
      "name": "FREE",
      "Finished": 5207,
      "Failed": 1573
    },
    {
      "name": "NAYAX",
      "Finished": 3201,
      "Failed": 1629
    },
    {
      "name": "RFID",
      "Finished": 853,
      "Failed": 395
    }
  ]

  const CustomTick = (props) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text className="badge" x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  }
  useEffect(() => {
    if (dataMain) {

      let totalFailedItems = 0;
      let totalFinishedItems = 0;

      dataMain.forEach(item => {
        totalFailedItems += item.Failed;
        totalFinishedItems += item.Finished;
      });

      setTotalFailed(totalFailedItems);
      setTotalFinished(totalFinishedItems);
    }
  }, [dataMain]);

  return (
    <>
      <div class="card shadow mb-4" style={{ borderRadius: '10px', paddingBottom: 144 }}>
        <div class="card-body">
          <div className="card-header" style={{ background: '#fff' }}>
            <div class="row">
              <div class="col-md-6"><strong class="card-title">SESSIONS</strong> </div>
              <div class="col-md-6 fw-bold text-end">{graphType} </div>
            </div>
            <div class="row">
              <div class="col-md-6 totalkwhleft text-dark">Finished: {totalFinished}</div>
              <div class="col-md-6 totalkwhright text-dark">Failed: {totalFailed}</div>
            </div>
          </div>
          <div class="clearfix"></div>
          <Table>
            <TableRow>
              <TableCell>
                <div className="responsiveCharts">
                  <ResponsiveContainer>
                    <ReactECharts
                      option={getOption()}
                      style={{ height: '500px', width: '100%', paddingBottom: 100 }}
                    />
                  </ResponsiveContainer>
                  {/* <div className="w-100 px-3 d-flex flex-row justify-content-center pb-4">
                    <span> <SquareIcon sx={{ color: "#FF0000" }} /> Failed</span>
                    <span>  <SquareIcon sx={{ color: "#00CC00" }} /> Finished</span>
                  </div> */}
                </div>
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>
    </>
  );
};

export default SessionsPie;
