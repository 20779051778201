import { CircularProgress } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Controls from "../../components/controls/Controls";
import { FetchContext } from '../../context/FetchContext';

const SiteGroupsSelect = (props) => {
  const { value = '', error = null, onChange, siteGroupSelected, setSiteGroupSelected, allValues = null } = props;
  const fetchContext = useContext(FetchContext);

  const [loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);
  useEffect(() => {
    setLoaded(false);
    const getAllSiteGroups = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          '/select-site-groups'
        );
        setValues(data.data);
        setLoaded(true);
      } catch (err) {
        console.log('the err', err);
      }
    };

    getAllSiteGroups(fetchContext);

  }, [fetchContext]);
  return (
    <>
      {loaded
        ?
        <Controls.Select
          name="group_id"
          label="Site Account"
          value={value}
          //onBlur={value}
          required={true}
          onChange={(e) => {
            // setSiteGroupSelected((values.filter(item => item.id === e.target.value))[0])
            // allValues.ac_price_plan_id = null
            // allValues.price_plan_id = null
            onChange(e)
          }}
          options={values}
          error={error}
        />
        :
        // <Controls.Input disabled={true} endAdornment={<Typography color="textSecondary" variant="overline"><CircularProgress size="1rem" /></Typography>} />
        <Controls.Input name="group_id" label="Site Account" style={{ width: "100%" }}><CircularProgress size="1rem" /> </Controls.Input>
      }

    </>
  )
}

export default SiteGroupsSelect;
