import React, { useState, useContext, useEffect } from "react";
import { FetchContext } from "../../context/FetchContext";
import ChargerGroupForm from "./ChargerGroupForm";
import { Paper, makeStyles, TableBody, TableRow, TableCell, Toolbar, Typography, Tooltip } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../../components/Popup";
import Loader from "../../components/Loader";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Filter from "./Filter";
import { useIsDesktop } from "../../context/DisplayContext";
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
  searchIcon: {
    marginTop: "25px",
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  exportIcon: {
    marginTop: "25px",
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  headerContainer: {
    padding: "18px 0",
    alignItems: "center",
    [theme.breakpoints.between("md", "lg")]: {
      padding: "18px 0",
    },
  },
}));
const ChargerGroups = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [counts, setCounts] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [showSearch, setShowSearch] = useState(true);
  const [filterObject, setFilterObject] = useState({
    search_keyword: null,
    is_search: !showSearch,
    export: false,
  });
  let headCells = [
    { id: "name", label: "Charger Group Name" },
    { id: "description", label: "Description" },
    { id: "charging_stations", label: "Charging Stations" },
    { id: "actions", label: "", disableSorting: true },
  ];
  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }
  const {
    TblContainer,
    TblHead,
  } = useTable(headCells);
  const getAll = async (fetchContext, page) => {
    try {
      const { data } = await fetchContext.authAxios.get(`/charging-groups?page=${page}`);
      console.log("data charger group ",data);
      
      setRecords(data.data);
      setTotalRecord(data.totalItems);
      if (data.totalItems && data.totalItems > 20) {
        let count = Math.ceil(data.totalItems / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }
      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };
  useEffect(() => {
    // getAll(fetchContext, page);
    filterAction(fetchContext, page, filterObject);
    // getAll(fetchContext, page);
    // filterAction(fetchContext, page, filterObject);
  }, [fetchContext, page]);
  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };
  const handleSubmit = (values) => {
    fetchContext.authAxios
      .post("/charging-groups", values)
      .then(({ data }) => {
        if (data.status) {
          // getAll(fetchContext, page);
          filterAction(fetchContext, page, filterObject);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors.name) {
            setNotify({
              isOpen: true,
              message: `Charger Group can't be created as: ${data.errors.name[0]}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Charger Group can't be created as: ${err}`,
          type: "error",
        });
      });
  };
  const handleEdit = (id, values) => {
    fetchContext.authAxios
      .put(`/charging-groups/${id}`, values)
      .then(({ data }) => {
        if (data.status) {
          // getAll(fetchContext, page);
          filterAction(fetchContext, page, filterObject);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors.name) {
            setNotify({
              isOpen: true,
              message: `Charger Group can't be edited as: ${data.errors.name[0]}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Charger Group can't be edited as: ${err}`,
          type: "error",
        });
      });
  };
  const handleDelete = (id) => {
    fetchContext.authAxios
      .delete(`/charging-groups/${id}`)
      .then(({ data }) => {
        if (data.status) {
          // getAll(fetchContext, page);
          filterAction(fetchContext, page, filterObject);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else {
          if (data.data) {
            setNotify({
              isOpen: true,
              message: `Charger Group can't be deleted as: ${data.data}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Charger Group can't be deleted as: ${err}`,
          type: "error",
        });
      });
  };
  const addOrEdit = (chargerGroup, resetForm) => {
    if (chargerGroup.id === 0 || chargerGroup.id === undefined) handleSubmit(chargerGroup);
    else handleEdit(chargerGroup.id, chargerGroup);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };
  const closeForm = () => {
    setOpenPopup(false);
  };
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    handleDelete(id);
  };
  const getChargingStationsString = (chargingStations) => {
    let str = "";
    chargingStations?.forEach((item) => {
      if (str === "") {
        str = item.name;
      } else {
        str = str + " , " + item.name;
      }
    });
    return str;
  };
  const onShowHideSearch = () => {
    if (!showSearch) filterAction(fetchContext, 1);
    setFilterObject({
      search_keyword: null,
      is_search: !showSearch,
      export: false,
    });
    setShowSearch(!showSearch);
    // setLoaded(false);
    setPage(1);
    // getAll(fetchContext, 1);
  };
  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
  };
  const onFilterSubmit = (values) => {
    setPage(1);
    filterAction(fetchContext, page, values);
  };
  const filterAction = async (fetchContext, page, filterObject) => {
    fetchContext.authAxios
      .post(`filter-charging-groups?page=${page}`, filterObject)
      .then(({ data }) => {
        setRecords(data.data);
      setTotalRecord(data.totalItems);
      if (data.totalItems && data.totalItems > 20) {
        let count = Math.ceil(data.totalItems / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }
      setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
        {fetchContext.isAdmin &&<div className={classes.searchIcon} onClick={onShowHideSearch}>
            {showSearch ? <Tooltip title="Search"><SearchIcon /></Tooltip> : <Tooltip title="Remove Search"><SearchOffIcon /></Tooltip>}
          </div>}
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">Charger Groups</Typography>
          </div>
            <Controls.Button
              style={{ color: "#a31112", border: "1px solid #a31112" }}
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
            />
        </Toolbar> */}
        <div className={`row ${classes.headerContainer}`}>
          <div className="col">
            <div className={classes.pageTitle}>
              {}
                Charger Groups
              {}
            </div>
          </div>
          <div className="col" style={{ textAlign: "right" }}>
            <div className="col" style={{ display: "inline-block" }}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
            {/* <div className='col' style={{display:'inline-block' }} >              
                <div className={classes.exportIcon}>
                  <ExportData filterObject={filterObject} />
                </div>             
              </div> */}
            <div className="col" style={{ display: "inline-block" }}>
              {}
                <Controls.Button
                  style={{ color: "#a31112", border: "1px solid #a31112" }}
                  text="Add New"
                  variant="outlined"
                  isPaddingTop={false}
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                />
              {}
            </div>
          </div>
        </div>
        {!showSearch && <Filter showSearch={showSearch} setFilterObject={setFilterObject} onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideSearch} />}
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{getChargingStationsString(item.charging_stations)}</TableCell>
                  {}
                  <TableCell class="text-nowrap">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        openInPopup(item);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to delete this record?",
                          subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            onDelete(item.id);
                          },
                        });
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                  {}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
            />
          </Stack>
        </div>
      </Paper>
      <Popup title="Charger Group" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <ChargerGroupForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} closeForm={closeForm} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};
export default ChargerGroups;
