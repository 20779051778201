import { ImportExport } from '@material-ui/icons';
import { CircularProgress, Snackbar, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { FetchContext } from '../../context/FetchContext';

const ExportData = ({ filterObject }) => {
  const fetchContext = useContext(FetchContext);

  const [loading, setLoading] = useState(false); // State to manage loading
  const [showMessage, setShowMessage] = useState(false); // State to manage message display

  function fetchConvertedDate(dateValue, timezone) {
    const dt = DateTime.fromISO(dateValue.replace(" ", "T"), { zone: "UTC" });

    const dtConverted = dt.setZone(timezone);
    return (
      dtConverted.toLocaleString(DateTime.DATE_SHORT) +
      " " +
      dtConverted.toLocaleString(DateTime.TIME_SIMPLE)
    );
  }

  const asyncFnComputeData = async () => {

setLoading(true); // Set loading to true before fetching data
    setShowMessage(true); // Show message when starting download
    const dataArr = [];
    await fetchContext.authAxios
      .post(`/filter-sites`, { ...filterObject, export: true })
      .then(({ data }) => {
        const pdata = data?.data;

      if (pdata) {
        for (let item of pdata) {
          //dataObj.sessionID = item.id;  
          let obj = {
            site: item?.name,
            // address: `"${item.address}, ${item.state}, ${item.country}"`,
            address: `"${item?.address?.replace(/,/g, '')}, ${item?.city}, ${item?.state}, ${item?.postal_code}, ${item?.country}"`,
            site_group: item?.site_group?.name,
            timezone: item?.timezone,
            price_plan_ac: item?.ac_price_plan ? item?.ac_price_plan?.name : '--',
            price_plan_dc: item?.dc_price_plan ? item?.dc_price_plan?.name : '--',
            account_number: item?.account_number,
            meter_number: item?.meter_number,
            utility_transformer_capacity: item?.utility_transformer_capacity,
            utility_service_capacity: item?.utility_service_capacity,
            longitude: item?.coordinates?.longitude,
            latitude: item?.coordinates?.latitude,
            postal_code: item?.postal_code,
          };
          dataArr.push(obj);
        }
          setLoading(false); // Set loading to false after fetching data
          setShowMessage(false); 
      }
    })
    return Promise.resolve(dataArr);
  };

  const columns = [
    { id: "site", displayName: "Site Name" },
    { id: "address", displayName: "Address" },
    { id: "site_group", displayName: "Site Account" },
    { id: "timezone", displayName: "Timezone" },
    { id: "price_plan_ac", displayName: "Price Plan AC" },
    { id: "price_plan_dc", displayName: "Price Plan DC" },
    { id: "account_number", displayName: "Account Number" },
    { id: "meter_number", displayName: "Meter Number" },
    {
      id: "utility_transformer_capacity",
      displayName: "Utility Transformer Capacity",
    },
    { id: "utility_service_capacity", displayName: "Utility Service Capacity" },
    { id: "longitude", displayName: "Longitude" },
    { id: "latitude", displayName: "Latitude" },
    { id: "postal_code", displayName: "Postal Code" },
  ];

  return (
    <>
      <CsvDownloader
        filename="sites"
        extension=".csv"
        separator=","
        //noHeader="true"
        //wrapColumnChar="'"
        columns={columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          {loading ? <CircularProgress size={24} /> : <ImportExport />}
        </Tooltip>
      </CsvDownloader>

      {/* Snackbar to display message */}
      <Snackbar
        open={showMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position at top center
        style={{ backgroundColor: "transperent" }} // Customize color to red
        message="Downloading data..."
      />
    </>
  );
};

export default ExportData;