import React, { createContext, useState } from 'react';
//import { useHistory } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  //const history = useHistory();
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user_id');
  const [authState, setAuthState] = useState({
    token,
    user_id: Number(user) 
  });

  const setAuthInfo = ({ token, user }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user_id', user?.id);
    localStorage.setItem('userName', user.first_name);
    localStorage.setItem('userRole', user.role_id);
    localStorage.setItem('userPermissionChSt', user.permissions.charging_stations);
    localStorage.setItem('technician', user.technician);

    if (user?.permissions?.global === true || user?.role?.role_name === "Administrator") {
      localStorage.setItem("chstF", true);
    }
    setAuthState({
      token,
      user_id: user?.id
    });
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem("chstF");
    //localStorage.clear();
    //https://stackoverflow.com/questions/65422098/usecontext-clear-when-logout-function-react-js
    setAuthState({ token: null, user: null });
  };

  const isAuthenticated = () => {
    if (!authState.token) {
      logout();
      return false;
    }
    try {
      let tokenData = authState.token;
      // let tokenData = jwtDecode(authState.token);
      if (Date.now() >= tokenData?.exp * 1000) {
        logout();
        return false; // Token expired
      }

      // Token Valid returning token string value
      return true;
    } catch (err) {
      logout();
      return false;
    }
  }

  return (
    <Provider
      value={{
        authState,
        setAuthState: authInfo => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };

