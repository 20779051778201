import React from 'react';
import { Dialog, DialogTitle, DialogContent, makeStyles, Typography } from '@material-ui/core';
import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(2)
    },
    dialogTitle: {
        '& .MuiDialogTitle-root': {
        root: {
                padding: 0,
            },
        },
        //paddingRight: '0px',
    }
}))
const PopupUser = (props) => {
    const { title, children, openPopup, closeForm } = props;
    const classes = useStyles();
    return (
      <>
        <Dialog
          open={openPopup}
          maxWidth="md"
          classes={{ paper: classes.dialogWrapper }}
          disableEnforceFocus
        >
          <DialogTitle className={classes.dialogTitle}>
            <div style={{ display: "flex" }}>
              <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                {title}
              </Typography>
              <Controls.ActionButton color="secondary" onClick={closeForm}>
                <CloseIcon />
              </Controls.ActionButton>
            </div>
          </DialogTitle>
          {}
          <DialogContent>{children}</DialogContent>
        </Dialog>
      </>
    );
}
export default PopupUser;
